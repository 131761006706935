import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import toast, { Toaster } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { DataGrid, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton, Modal, Paper, TextField } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function Inventory() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [refresh, set_refresh] = useState(false);
  const [loader, set_loader] = useState(true);
  const [inventory, set_inventory] = useState([]);
  const [update_inventory_list, set_update_inventory_list] = useState({});
  const [update_button, set_update_button] = useState(false);
  const [add_new_item, set_add_new_item] = useState(false);
  const [new_item_value, set_new_item_value]: any = useState({});
  const [remark_popup,set_remark_popup] =useState(false)

  useEffect(() => {
    set_update_button(false);
    set_add_new_item(false);
    set_loader(true);
    set_remark_popup(false);
    set_update_inventory_list({});
    set_new_item_value({});
    AxiosInstance()
      .get(`/sites/inventory/${id}`)
      .then(({ data }: any) => {
        let inventory_name = Object.keys(data.inventory);
        let inventory_temp: any = [];
        let yesterday_inventory_names = Object.keys(data.yesterday_stock);
        let today_inventory_names = Object.keys(data.today_stock);
        for (let i = 0; i < inventory_name.length; i++) {
          let today_utilisation = { stocked: 0, used: 0 };
          let yesterday_utilisation = { stocked: 0, used: 0 };
          if (yesterday_inventory_names.indexOf(inventory_name[i]) !== -1) {
            yesterday_utilisation = data.yesterday_stock[inventory_name[i]];
          }
          if (today_inventory_names.indexOf(inventory_name[i]) !== -1) {
            today_utilisation = data.today_stock[inventory_name[i]];
          }

          inventory_temp.push({
            id: i + 1,
            name: inventory_name[i],
            quantity: data.inventory[inventory_name[i]],
            min_quantity: data.min_quantity[inventory_name[i]],
            daily_usage: data.daily_usage[inventory_name[i]],
            today_utilisation: today_utilisation,
            yesterday_utilisation: yesterday_utilisation,
          });
        }
        set_inventory(inventory_temp);
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh]);

  const update_inventory_item = (inventory_name: string, add: boolean) => {
    let temp_update_inventory: any = update_inventory_list;
    if (Object.keys(temp_update_inventory).indexOf(inventory_name) === -1) {
      if (add) {
        temp_update_inventory[inventory_name] = 1;
      } else {
        temp_update_inventory[inventory_name] = -1;
      }
    } else {
      if (add) {
        temp_update_inventory[inventory_name] += 1;
      } else {
        temp_update_inventory[inventory_name] -= 1;
      }
      if (temp_update_inventory[inventory_name] === 0) {
        delete temp_update_inventory[inventory_name];
      }
    }
    let inventory_temp: any = inventory;
    for (let i = 0; i < inventory_temp.length; i++) {
      if (inventory_temp[i]["name"] === inventory_name) {
        if (add) {
          inventory_temp[i]["quantity"] += 1;
        } else {
          inventory_temp[i]["quantity"] -= 1;
        }
      }
    }

    if (Object.keys(temp_update_inventory).length === 0) {
      set_update_button(false);
    } else {
      set_update_button(true);
    }
    set_update_inventory_list(temp_update_inventory);
  };

  const update_inventory_api_call = () => {
    set_loader(true);
    let remark:any = null
    let update_inventory_list_temp:any = update_inventory_list
    if(Object.keys(update_inventory_list_temp).indexOf("remark") !== -1){
      remark= update_inventory_list_temp["remark"]
      delete update_inventory_list_temp["remark"]
    }
    AxiosInstance()
      .put(`/sites/inventory/${id}`, {
        update_inventory:update_inventory_list_temp,
        remarks:remark
      })
      .then(({ data }: any) => {
        set_refresh(!refresh);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  

  const update_new_inventory_value = (name: any, value: any) => {
    if (name !== "name") {
      value = parseInt(value);
    }
    let temp: any = new_item_value;
    temp[name] = value;
    set_new_item_value(temp);
  };

  const delete_item_call= (item_name:any)=>{
    set_loader(true)
    AxiosInstance()
    .delete(`/sites/inventory/${id}?item_name=${item_name}`)
    .then(({ data }: any) => {
      set_refresh(!refresh);
    })
    .catch((err: any) => {
      toast.error(err.message);
      set_loader(false);
    });
  }

  const handle_new_item_add_call = () => {
    if (
      new_item_value.name &&
      new_item_value.quantity &&
      new_item_value.min_quantity &&
      new_item_value.daily_usage
    ) {
      set_loader(true);
      AxiosInstance()
        .post(`/sites/inventory/${id}`, new_item_value)
        .then(({ data }: any) => {
          set_refresh(!refresh);
        })
        .catch((err: any) => {
          toast.error(err.message);
          set_loader(false);
        });
    } else {
      toast.error("Provide all values for new inventory item.");
    }
  };

  const inventory_columns = [
    {
      headerName: "S.No.",
      width: 100,
      field: "id",
    },
    {
      headerName: "Name",
      width: 200,
      field: "name",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.min_quantity) {
          return (
            <div className="rowitem" style={{ backgroundColor: "#FFA8B5" }}>
              {params.row.name}
            </div>
          );
        } else {
          return <div className="rowitem">{params.row.name}</div>;
        }
      },
    },
    {
      headerName: "Minimum Quantity",
      width: 150,
      field: "min_quantity",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.min_quantity) {
          return (
            <div className="rowitem" style={{ backgroundColor: "#FFA8B5" }}>
              {params.row.min_quantity}
            </div>
          );
        } else {
          return <div className="rowitem">{params.row.min_quantity}</div>;
        }
      },
    },
    {
      headerName: "Current Quantity",
      width: 150,
      field: "quantity",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.quantity) {
          return (
            <div className="rowitem" style={{ background: "#FFA8B5" }}>
              {params.row.min_quantity}
            </div>
          );
        } else {
          return <div className="rowitem">{params.row.quantity}</div>;
        }
      },
    },
    {
      headerName: "Update Quantity",
      width: 150,
      field: "update_quantity",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.quantity) {
          return (
            <div className="rowitem" style={{ backgroundColor: "#FFA8B5" }}>
              <IconButton
                style={{ color: "#FFFFFF", background: "#FFCCCB" }}
                disabled={loader || params.row.quantity === 0}
                onClick={() => {
                  update_inventory_item(params.row.name, false);
                }}
              >
                <RemoveIcon />
              </IconButton>
              <IconButton
                disabled={loader}
                style={{ color: "#FFFFFF", background: "#90EE90" }}
                onClick={() => {
                  update_inventory_item(params.row.name, true);
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          );
        } else {
          return (
            <div className="rowitem">
              <IconButton
                disabled={loader || params.row.quantity === 0}
                style={{
                  color: "#FFFFFF",
                  background: "#FFCCCB",
                  padding: 1,
                  margin: 1,
                }}
                onClick={() => {
                  update_inventory_item(params.row.name, false);
                }}
              >
                <RemoveIcon />
              </IconButton>
              <IconButton
                disabled={loader}
                style={{
                  color: "#FFFFFF",
                  background: "#90EE90",
                  padding: 1,
                  margin: 1,
                }}
                onClick={() => {
                  update_inventory_item(params.row.name, true);
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          );
        }
      },
    },
    {
      headerName: "Ideal Daily Usage",
      width: 150,
      field: "daily_usage",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.quantity) {
          return (
            <div className="rowitem" style={{ background: "#FFA8B5" }}>
              {params.row.daily_usage}
            </div>
          );
        } else {
          return <div className="rowitem">{params.row.daily_usage}</div>;
        }
      },
    },
    {
      headerName: "Today's Utilisation",
      width: 150,
      field: "today_util",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.quantity) {
          return (
            <div className="rowitem" style={{ background: "#FFA8B5" }}>
              <div>STOCKED: {params.row.today_utilisation.stocked}</div>
              <div>USED: {params.row.today_utilisation.used}</div>
            </div>
          );
        } else {
          return (
            <div className="rowitem">
              <div>STOCKED: {params.row.today_utilisation.stocked}</div>
              <div>USED: {params.row.today_utilisation.used}</div>
            </div>
          );
        }
      },
    },
    {
      headerName: "Yesterday's Utilisation",
      width: 150,
      field: "yes_util",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.quantity) {
          return (
            <div className="rowitem" style={{ background: "#FFA8B5" }}>
              <div>STOCKED: {params.row.yesterday_utilisation.stocked}</div>
              <div>USED: {params.row.yesterday_utilisation.used}</div>
            </div>
          );
        } else {
          return (
            <div className="rowitem">
              <div>STOCKED: {params.row.yesterday_utilisation.stocked}</div>
              <div>USED: {params.row.yesterday_utilisation.used}</div>
            </div>
          );
        }
      },
    },
    {
      headerName: "Delete",
      width: 150,
      field: "actions",
      renderCell: (params: any) => {
        if (params.row.quantity < params.row.quantity) {
          return (
            <div className="rowitem" style={{ background: "#FFA8B5" }}>
              <IconButton onClick={()=>{delete_item_call(params.row.name)}}><DeleteForeverIcon/></IconButton>
            </div>
          );
        } else {
          return (
            <div className="rowitem">
              <IconButton onClick={()=>{delete_item_call(params.row.name)}}><DeleteForeverIcon/></IconButton>
            </div>
          );
        }
      },
    },
  ];
  return (
    <Navbar>
      <PageLayout heading="Inventory">
        <div style={{ margin: 2, padding: 2, height: "60vw" }}>
          {update_button ? (
            <Button
              variant="contained"
              disabled={!update_button}
              onClick={()=>{set_remark_popup(true)}}
              style={{ background: "#046499", color: "#FFFFFF" }}
            >
              Update
            </Button>
          ) : (
            <Button variant="contained" disabled={!update_button}>
              Update
            </Button>
          )}
          <Button
            variant="contained"
            disabled={loader}
            style={{ background: "#046499", color: "#FFFFFF", marginLeft: 10 }}
            onClick={() => {
              set_new_item_value({});
              set_add_new_item(true);
            }}
          >
            Add Item
          </Button>
          <Button
            variant="contained"
            disabled={loader}
            onClick={() => {
              navigate(`/inventory_history/${id}`);
            }}
            style={{ background: "#046499", color: "#FFFFFF", marginLeft: 10 }}
          >
            View Inventory History
          </Button>
          <DataGrid
            autoHeight={true}
            style={{ overflowY: "scroll", margin: 10 }}
            getRowId={(row: any) => row.id}
            rows={inventory}
            columns={inventory_columns}
            components={{ Toolbar: GridToolbar }}
          />
        </div>
      </PageLayout>
      <Modal
        open={add_new_item}
        onClose={() => {
          set_add_new_item(false);
        }}
        style={{ marginTop: "20%", width: "30%", marginLeft: "35%" }}
      >
        <Paper elevation={5}>
          <div style={{ padding: 10 }}>
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Inventory Name"
              variant="outlined"
              type="text"
              value={new_item_value.name}
              onChange={(e) => {
                update_new_inventory_value("name", e.target.value);
              }}
              disabled={loader}
            />
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Minimum Quantituy"
              variant="outlined"
              type="number"
              value={new_item_value.min_quantity}
              onChange={(e) => {
                update_new_inventory_value("min_quantity", e.target.value);
              }}
              disabled={loader}
            />
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              type="text"
              value={new_item_value.quantity}
              onChange={(e) => {
                update_new_inventory_value("quantity", e.target.value);
              }}
              disabled={loader}
            />
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Ideal Daily Usage"
              variant="outlined"
              type="text"
              value={new_item_value.daily_usage}
              onChange={(e) => {
                update_new_inventory_value("daily_usage", e.target.value);
              }}
              disabled={loader}
            />
            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "90%",
              }}
              onClick={handle_new_item_add_call}
              disabled={loader}
            >
              Add Item
            </Button>
          </div>
        </Paper>
      </Modal>
      <Modal
        open={remark_popup}
        onClose={() => {
          set_remark_popup(false);
        }}
        style={{ marginTop: "20%", width: "30%", marginLeft: "35%" }}
      >
        <Paper elevation={5}>
          <div style={{ padding: 10 }}>
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Remark"
              variant="outlined"
              type="text"
              value={new_item_value.name}
              onChange={(e) => {
                set_update_inventory_list({...update_inventory_list,remark:e.target.value})
              }}
              disabled={loader}
            />
            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "90%",
              }}
              onClick={update_inventory_api_call}
              disabled={loader}
            >
              Update
            </Button>
          </div>
        </Paper>
      </Modal>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
