import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import byte_flyt_logo from "../../Constants/Media/Images/byte_flyt_logo.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import wave_line from "../../Constants/Media/SVG/wave_line.svg";
import "../../Constants/Fonts/qanelas-soft-cufonfonts-webfont/style.css";
import toast, { Toaster } from "react-hot-toast";
import AxiosInstance from "../../Components/axiosinstance";
import { Dna } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { color } from "@mui/system";

export default function ByteflytResetPassword() {
  const [email, set_email] = useState("");
  const [otp_sent, set_otp_sent] = useState(false);
  const [otp, set_otp] = useState("");
  const [otp_verified, set_otp_verified] = useState(false);
  const [password, set_password] = useState("");
  const [reenter_password, set_reenter_password] = useState("");
  const [show_password, set_show_password] = useState(false);
  const [show_reenter_password, set_show_reenter_password] = useState(false);
  const [loader, set_loader] = useState(false);

  const handleSubmit = () => {
    if (otp_sent) {
      if (otp_verified) {
        if (password.length > 7) {
          if (password !== reenter_password) {
            toast.error("Both the Passwords do not match.");
          } else {
            set_loader(true);
            AxiosInstance()
              .post("/byteflyt/resetpassword", {
                email: email,
                otp: otp,
                password: password,
              })
              .then(({ data }: any) => {
                toast.success(
                  "Password reset successfully. Please use the new password to Login."
                );
                set_loader(false);
                // @ts-ignore
                window.location = "/byteflyt/login";
              })
              .catch((err: any) => {
                toast.error(err.message);
                set_loader(false);
              });
          }
        } else {
          toast.error("Password must contain atleast 8 characters.");
        }
      } else {
        if (otp.length === 0) {
          toast.error("Please provide the OTP Sent");
        } else {
          set_loader(true);
          AxiosInstance()
            .post("/byteflyt/verifyOTP", {
              email: email,
              otp: otp,
            })
            .then(({ data }: any) => {
              set_otp_verified(true);
              set_loader(false);
            })
            .catch((err: any) => {
              toast.error(err.message);
              set_loader(false);
            });
        }
      }
    } else {
      if (
        email.length !== 0 &&
        email.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.com$")
      ) {
        set_loader(true);
        AxiosInstance()
          .post("/byteflyt/sendOTP", {
            email: email,
          })
          .then(({ data }: any) => {
            set_otp_sent(true);
            toast.success("OTP sent to registered Email and phone number.");
            set_loader(false);
          })
          .catch((err: any) => {
            toast.error(err.message);
            set_loader(false);
          });
      } else {
        toast.error("Please enter a valid email address");
      }
    }
  };

  const resendOTP = ()=>{
      set_loader(true);
      AxiosInstance()
      .post("/byteflyt/sendOTP", {
          email: email,
        })
      .then(({ data }: any) => {
          set_otp_sent(true);
          toast.success("OTP resent to registered Email and phone number.");
          set_loader(false);
        })
      .catch((err: any) => {
          toast.error(err.message);
          set_loader(false);
        });
  }

  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        position: "absolute",
        top: 0,
        width: "100%",
        bottom: 0,
        backgroundColor: "black",
        backgroundImage: `url(${wave_line})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        fontFamily: "Qanelas Soft Medium",
        fontSize: 12,
      }}
    >
      <Grid container spacing={2} p={2}>
        <Grid item xs={1} sm={3} lg={4.5} xl={5}></Grid>
        <Grid item xs={10} sm={6} lg={3} xl={2}>
          <Paper elevation={10} style={{ borderRadius: 40 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 5,
                paddingBottom: 5,
              }}
              p={2}
            >
              <img src={byte_flyt_logo} style={{ width: "90%" }} />
              <h1 style={{ color: "#046499" }}>ADMIN</h1>
              {otp_verified ? (
                <>
                  <TextField
                    style={{ width: "90%", marginBottom: 10 }}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    disabled={loader}
                    type={show_password ? "text" : "password"}
                    value={password}
                    onChange={(e) => set_password(e.target.value)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => set_show_password(!show_password)}
                            onMouseDown={() =>
                              set_show_password(!show_password)
                            }
                          >
                            {show_password ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    style={{ width: "90%", marginBottom: 10 }}
                    id="outlined-basic"
                    label="Re-Enter Password"
                    variant="outlined"
                    disabled={loader}
                    type={show_reenter_password ? "text" : "password"}
                    value={reenter_password}
                    onChange={(e) => set_reenter_password(e.target.value)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              set_show_reenter_password(!show_reenter_password)
                            }
                            onMouseDown={() =>
                              set_show_reenter_password(!show_reenter_password)
                            }
                          >
                            {show_reenter_password ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={email}
                    disabled={loader || otp_sent}
                    type="email"
                    onChange={(e) => set_email(e.target.value)}
                  />
                  {otp_sent ? (
                    <>
                      <TextField
                        style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                        id="outlined-basic"
                        label="OTP"
                        variant="outlined"
                        value={otp}
                        disabled={loader}
                        type="email"
                        onChange={(e) => set_otp(e.target.value)}
                      />
                    </>
                  ) : null}
                </>
              )}

              <Button
                variant="contained"
                style={{
                  background: "#046499",
                  fontSize: 16,
                  marginBottom: 10,
                  width: "70%",
                  borderRadius: 10,
                }}
                onClick={handleSubmit}
                disabled={loader}
              >
                {otp_sent ? (
                  <>{otp_verified ? <>Change Password</> : <>Verify OTP</>}</>
                ) : (
                  <>Send OTP</>
                )}
              </Button>

              {otp_sent ?(<>{otp_verified?(null):(<>{loader?(<div
              >Resend OTP</div>):(<div
                onClick={resendOTP}
                style = {{color:"#046499",cursor: "pointer"}}
              >Resend OTP</div>)}</>)}</>):(null)}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={3} lg={4.5} xl={5}></Grid>
      </Grid>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}
