import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import AddIcon from "@mui/icons-material/Add";

export default function Workforce() {
  const [loader, set_loader] = useState(false);
  const [refresh, set_refresh] = useState(false);
  const [add_workforce, set_add_workforce] = useState(false);
  const [update_workforce, set_update_workforce] = useState(true);
  const [workforce_to_update, set_workforce_to_update]: any = useState({});
  const [workforce, set_workforce] = useState([]);
  const [allowed_users, set_allowed_users]: any = useState(0);
  const [current_users, set_current_users]: any = useState(0);

  useEffect(() => {
    set_loader(true);
    AxiosInstance()
      .get("/users/workforce")
      .then(({ data }: any) => {
        set_workforce(data.users);
        set_allowed_users(data.allowed_users);
        set_current_users(data.active_users);
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh]);

  const update_workforce_popup = (workforce_details: any) => {
    set_add_workforce(true);
    set_update_workforce(true);
    set_workforce_to_update({
      _id: workforce_details._id,
      name: workforce_details.name,
      email: workforce_details.email,
      phone_number: workforce_details.phone_number,
    });
  };

  const add_new_workforce_popup = () => {
    if (current_users < allowed_users) {
      set_add_workforce(true);
      set_update_workforce(false);
      set_workforce_to_update({});
    } else {
      toast.error(
        `Can not add a new account. Max account limit reached: ${allowed_users}`
      );
    }
  };

  const handle_workforce_popup = () => {
    // To Check if all fields are correct
    let valid_fields = true;
    if (workforce_to_update.name) {
      if (workforce_to_update.name.length === 0) {
        valid_fields = false;
        toast.error("Please provide workforce name.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide workforce name.");
    }

    if (workforce_to_update.email) {
      if (workforce_to_update.email.length === 0) {
        valid_fields = false;
        toast.error("Please provide workforce email.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide workforce email.");
    }

    if (workforce_to_update.phone_number) {
      if (workforce_to_update.phone_number.length !== 10) {
        valid_fields = false;
        toast.error("Please provide valid workforce number.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide valid workforce number.");
    }

    if (valid_fields) {
      if (update_workforce) {
        update_workforce_call();
      } else {
        create_workforce();
      }
    }
  };

  const update_workforce_call = () => {
    set_loader(true);
    AxiosInstance()
      .put(`/users/workforce`, workforce_to_update)
      .then(({ data }) => {
        set_loader(false);
        toast.success("workforce updated successfully");
        set_add_workforce(false);
        set_refresh(!refresh);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };
  const create_workforce = () => {
    set_loader(true);
    AxiosInstance()
      .post("/users/workforce", workforce_to_update)
      .then(({ data }) => {
        set_loader(false);
        toast.success("workforce added successfully");
        set_add_workforce(false);
        set_refresh(!refresh);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  function stringAvatar(name: string) {
    let first_letter = "";
    let second_letter = "";
    if (name.indexOf(" ") === -1) {
      first_letter = name[0];
    } else {
      first_letter = name.split(" ")[0][0];
      second_letter = name.split(" ")[1][0];
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${first_letter}${second_letter}`,
    };
  }

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const update_workforce_value = (field: any, value: any) => {
    set_workforce_to_update({ ...workforce_to_update, [field]: value });
  };

  const toggleAccount = (
    workforce_id: any,
    status: any,
    workforce_name: any
  ) => {
    if (!status && current_users >= allowed_users) {
      toast.error(
        `Can not activate account: ${workforce_name}, as account limit is already reached: ${allowed_users}. Deactivate another account to activate this account`
      );
    } else {
      set_loader(true);
      AxiosInstance()
        .post(`/users/toggle_workforce_account`, {
          _id: workforce_id,
          status: status,
        })
        .then(({ data }) => {
          if (status) {
            toast.success(`${workforce_name}: Account Suspended`);
          } else {
            toast.success(`${workforce_name}: Account Activated`);
          }

          set_loader(false);
          set_refresh(!refresh);
        })
        .catch((err) => {
          toast.error(err.message);
          set_loader(false);
        });
    }
  };

  const workforce_columns: any = [
    {
      headerName: "Name",
      width: 200,
      field: "name",
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar {...stringAvatar(params.row.name)} />
              <div style={{ marginLeft: 5 }}> {params.row.name} </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "account_status",
      headerName: "Account Status",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            {params.row.account_deactivated ? <>Suspended</> : <>Active</>}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone_number",
      headerName: "Number",
      width: 200,
    },
    {
      headerName: "Actions",
      width: 200,
      renderCell: (params: any) => {
        if (params.row.role && params.row.role === "Client") {
          return <div className="rowitem">No Action Allowed</div>;
        }
        return (
          <div className="rowitem">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={
                  params.row.account_deactivated
                    ? "Activate Account"
                    : "Suspend Account"
                }
              >
                <IconButton
                  style={{ margin: 5 }}
                  onClick={() => {
                    toggleAccount(
                      params.row._id,
                      !params.row.account_deactivated,
                      params.row.name
                    );
                  }}
                >
                  {params.row.account_deactivated ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <BlockIcon style={{ color: "red" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit workforce Deatils">
                <IconButton
                  style={{ margin: 5 }}
                  onClick={() => {
                    update_workforce_popup(params.row);
                  }}
                >
                  <EditIcon style={{ color: "#5DB0E0" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Navbar>
      <PageLayout heading="Workforce">
        <p style={{ fontSize: 16, fontWeight: 600, margin: 20 }}>
          <span>Allowed Accounts: {allowed_users}</span>
          <span style={{ float: "right" }}>
            Active Accounts: {current_users}
          </span>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 2,
            padding: 2,
            height: 650,
            width: "95%",
          }}
        >
          <DataGrid
            style={{ overflowY: "scroll" }}
            getRowId={(row: any) => row._id}
            rows={workforce}
            columns={workforce_columns}
          />
        </div>
      </PageLayout>
      <Tooltip title="Add New Account">
        <IconButton
          style={{
            backgroundColor: "#046499",
            position: "fixed",
            bottom: 15,
            right: 15,
          }}
          onClick={add_new_workforce_popup}
        >
          <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
        </IconButton>
      </Tooltip>
      <Toaster />
      <Dialog
        open={add_workforce}
        onClose={() => {
          set_add_workforce(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                fontSize: 24,
                fontWeight: 700,
                color: "#046499",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {update_workforce ? (
                <>Update workforce</>
              ) : (
                <>Add New workforce</>
              )}
            </div>
            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "100%",
              }}
              onClick={handle_workforce_popup}
              disabled={loader}
            >
              {update_workforce ? <>Update workforce</> : <>Create workforce</>}
            </Button>
          </div>
          <DialogContent>
            <DialogContentText>
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={workforce_to_update.name}
                disabled={loader}
                onChange={(e) => update_workforce_value("name", e.target.value)}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={workforce_to_update.email}
                disabled={loader}
                onChange={(e) =>
                  update_workforce_value("email", e.target.value)
                }
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Number"
                variant="outlined"
                value={workforce_to_update.phone_number}
                disabled={loader}
                type="number"
                onChange={(e) =>
                  update_workforce_value("phone_number", e.target.value)
                }
              />
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
