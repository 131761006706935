import { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import { toast, Toaster } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import AxiosInstance from "../../Components/axiosinstance";
import { useNavigate, useParams } from "react-router-dom";
import BlockIcon from "@mui/icons-material/Block";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";

export function ClientDetails() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [client_name, set_client_name] = useState("");
  const [loader, set_loader] = useState(false);
  const [refresh, set_refresh] = useState(false);
  const [sites, set_sites] = useState([]);
  const [client_details, set_client_details]: any = useState({});
  const [active_users, set_active_users] = useState(0);
  const [expiring_sites, set_expiring_sites] = useState(0);
  const [expired_sites, set_expired_sites] = useState(0);
  const [add_site, set_add_site] = useState(false);
  const [update_site, set_update_site] = useState(false);
  const [site_to_update, set_site_to_update]: any = useState({
    selected_modules: {
      ANALYSIS: true,
      TASKMANAGEMENT: false,
      CONTROL: false,
    },
  });

  const site_status_mapping: any = {
    3: "ACTIVE",
    4: "SUSPENDED",
    5: "INACTIVE",
    0: "ONBOARDED",
    1: "HARDWARESETUP",
    2: "CONFIGURED",
  };

  useEffect(() => {
    set_loader(true);
    AxiosInstance()
      .get(`/byteflyt/client_details/${id}`)
      .then(({ data }) => {
        console.log(data);
        set_sites(data.sites);
        set_client_details(data.client_info);
        set_client_name(data.client_info.name);
        set_active_users(data.active_users_count);
        set_expiring_sites(data.expiring_soon_count);
        set_expired_sites(data.expired_sites_count);
        set_loader(false);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh, id]);

  const create_new_site_pop = () => {
    set_add_site(true);
    set_update_site(false);
    set_site_to_update({
      name: "",
      latitude: "",
      longitude: "",
      site_size: "medium",
      site_start_date: moment().add(15, "days").format("YYYY-MM-DD"),
      package_validity: moment()
        .add(1, "years")
        .add(15, "days")
        .format("YYYY-MM-DD"),
      selected_modules: {
        ANALYSIS: true,
        TASKMANAGEMENT: false,
        CONTROL: false,
      },
    });
  };

  const create_update_site_popup = (plant_info: any) => {
    console.log(plant_info);
    set_add_site(true);
    set_update_site(true);
    set_site_to_update({
      _id: plant_info._id,
      name: plant_info.name,
      latitude: plant_info.latitude,
      longitude: plant_info.longitude,
      site_size: plant_info.site_size,
      site_start_date: moment(plant_info.site_start_date).format("YYYY-MM-DD"),
      package_validity: moment(plant_info.package_validity).format(
        "YYYY-MM-DD"
      ),
      selected_modules: plant_info.selected_modules,
    });
  };

  const update_site_value = (field: any, value: any) => {
    set_site_to_update({ ...site_to_update, [field]: value });
  };

  const handle_site_popup = () => {
    let valid_fields = true;
    if (site_to_update.name) {
      if (site_to_update.name.length === 0) {
        valid_fields = false;
        toast.error("Please provide Site name.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide Site name.");
    }
    if (site_to_update.latitude) {
      if (site_to_update.latitude.length === 0) {
        valid_fields = false;
        toast.error("Please provide Site Latitude.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide Site Latitude");
    }
    if (site_to_update.longitude) {
      if (site_to_update.longitude.length === 0) {
        valid_fields = false;
        toast.error("Please provide Site Longitude.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide Site Longitude.");
    }

    if (valid_fields) {
      if (update_site) {
        update_site_call();
      } else {
        create_new_site_call();
      }
    }
  };

  const handle_checkbox_change = (field: string, value: boolean) => {
    set_site_to_update({
      ...site_to_update,
      selected_modules: { ...site_to_update.selected_modules, [field]: value },
    });
  };

  const create_new_site_call = () => {
    set_loader(true);
    AxiosInstance()
      .post("/byteflyt/create_new_site", {
        client_id: id,
        ...site_to_update,
      })
      .then(({ data }) => {
        toast.success("New Site Added");
        set_loader(false);
        set_add_site(false);
        set_refresh(!refresh);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  const update_site_call = () => {
    set_loader(true);
    AxiosInstance()
      .post("/byteflyt/update_site", site_to_update)
      .then(({ data }) => {
        toast.success("Site Updated");
        set_loader(false);
        set_add_site(false);
        set_refresh(!refresh);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  const site_columns: any = [
    {
      headerName: "Name",
      width: 200,
      field: "name",
      renderCell: (params: any) => {
        return (
          <div
            className="rowitem"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/site_details/${params.row._id}`);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: 5 }}> {params.row.name} </div>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Package Validity",
      field: "package_validity",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            {moment(params.row.package_validity).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      headerName: "Site Size",
      field: "site_size",
      width: 200,
    },
    {
      headerName: "Packages",
      field: "packages_selected",
      width: 400,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            {Object.keys(params.row.selected_modules).map(
              (module_name: any) => {
                if (params.row.selected_modules[module_name]) {
                  return <>{module_name},</>;
                }
              }
            )}
          </div>
        );
      },
    },
    {
      headerName: "Site Status",
      field: "site_status",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            {site_status_mapping[params.row.site_status]}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={
                  params.row.site_deactivated ? "Activate Site" : "Suspend Site"
                }
              >
                <IconButton style={{ margin: 5 }} onClick={() => {}}>
                  {params.row.site_deactivated ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <BlockIcon style={{ color: "red" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Site Deatils">
                <IconButton
                  style={{ margin: 5 }}
                  onClick={() => {
                    create_update_site_popup(params.row);
                  }}
                >
                  <EditIcon style={{ color: "#5DB0E0" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <Navbar>
      <PageLayout heading={`Client : ${client_name}`}>
        <div style={{ margin: 2, padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#95BDFF" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <WarehouseIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Total Sites
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {sites.length}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#F7C8E0" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <PeopleAltIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Total Active Users
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {active_users}/{client_details.client_users}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#90EE90" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <UpcomingIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Expiring Sites
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {expiring_sites}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#FFCCCB" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BrowseGalleryIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Expired Sites
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {expired_sites}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 2,
                  padding: 2,
                  height: 650,
                }}
              >
                <DataGrid
                  style={{ overflowY: "scroll" }}
                  getRowId={(row: any) => row._id}
                  rows={sites}
                  columns={site_columns}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </PageLayout>
      <Tooltip title="Add New Site">
        <IconButton
          style={{
            backgroundColor: "#046499",
            position: "fixed",
            bottom: 15,
            right: 15,
          }}
          onClick={create_new_site_pop}
        >
          <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={add_site}
        onClose={() => {
          set_add_site(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                fontSize: 24,
                fontWeight: 700,
                color: "#046499",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {update_site ? <>Update Site</> : <>Add New Site</>}
            </div>
            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "100%",
              }}
              onClick={handle_site_popup}
              disabled={loader}
            >
              {update_site ? <>Update Site</> : <>Create Site</>}
            </Button>
          </div>
          <DialogContent>
            <DialogContentText>
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Site Name"
                variant="outlined"
                value={site_to_update.name}
                onChange={(e) => {
                  update_site_value("name", e.target.value);
                }}
                disabled={loader}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Latitude"
                variant="outlined"
                value={site_to_update.latitude}
                onChange={(e) => {
                  update_site_value("latitude", e.target.value);
                }}
                disabled={loader}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Longitude"
                variant="outlined"
                value={site_to_update.longitude}
                onChange={(e) => {
                  update_site_value("longitude", e.target.value);
                }}
                disabled={loader}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Site Start Date"
                variant="outlined"
                type="date"
                value={site_to_update.site_start_date}
                onChange={(e) => {
                  update_site_value("site_start_date", e.target.value);
                }}
                disabled={loader}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Site Name"
                variant="outlined"
                type="date"
                value={site_to_update.package_validity}
                onChange={(e) => {
                  update_site_value("package_validity", e.target.value);
                }}
                disabled={loader}
              />
              <Box border={2} borderRadius={5}>
                <div style={{ margin: 8 }}>
                  Site Size
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={site_to_update.site_size}
                    onChange={(e) => {
                      update_site_value("site_size", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="small"
                      control={<Radio />}
                      label="Small"
                    />
                    <FormControlLabel
                      value="medium"
                      control={<Radio />}
                      label="Medium"
                    />
                    <FormControlLabel
                      value="large"
                      control={<Radio />}
                      label="Large"
                    />
                    <FormControlLabel
                      value="extra-large"
                      control={<Radio />}
                      label="Extra Large"
                    />
                  </RadioGroup>
                </div>
              </Box>
              <Box border={2} borderRadius={5} marginTop={2}>
                <div style={{ margin: 8 }}>
                  Packages
                  <div>
                    <div>
                      <Checkbox
                        checked={site_to_update.selected_modules.ANALYSIS}
                        disabled={true}
                      />
                      Analysis
                    </div>
                    <div>
                      <Checkbox
                        checked={site_to_update.selected_modules.TASKMANAGEMENT}
                        onChange={(e: any) => {
                          handle_checkbox_change(
                            "TASKMANAGEMENT",
                            e.target.checked
                          );
                        }}
                      />{" "}
                      Task Management
                    </div>
                    <div>
                      <Checkbox
                        checked={site_to_update.selected_modules.CONTROL}
                        onChange={(e: any) => {
                          handle_checkbox_change("CONTROL", e.target.checked);
                        }}
                      />{" "}
                      Site Control
                    </div>
                  </div>
                </div>
              </Box>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <Toaster />
    </Navbar>
  );
}
