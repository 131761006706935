import { Grid, Paper, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Switch from "@mui/joy/Switch";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import InfoIcon from "@mui/icons-material/Info";

export default function Control() {
  const [loader, set_loader] = useState(false);
  const [control, set_control]: any = useState({});
  const [control_config, set_control_config]: any = useState({});
  const [refresh, set_refresh] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    set_loader(true);
    AxiosInstance()
      .get(`/sites/controls/${id}`)
      .then(({ data }: any) => {
        set_control(data.control);
        set_control_config(data.control_config);
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh, id]);

  const toggle_control = (control_name: any, value: any) => {
    set_loader(true);
    AxiosInstance()
      .put(`/sites/controls/${id}`, {
        control_item: control_name,
        value: value,
        config: control_config[control_name],
      })
      .then(({ data }: any) => {
        set_refresh(!refresh);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  return (
    <Navbar>
      <PageLayout heading="Controls">
        <div style={{ margin: 10, padding: 10 }}>
          <Grid container spacing={2}>
            {Object.keys(control).map((control_item: any) => {
              return (
                <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                  <Paper elevation={5} style={{ padding: 5, borderRadius: 10 }}>
                    <div
                      style={{
                        display: "block",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 18 }}>
                        {control_item}
                      </div>
                      <span style={{ float: "right" }}>
                        <Tooltip title={control_config[control_item]["help"]}>
                          <InfoIcon />
                        </Tooltip>
                      </span>
                    </div>
                    <span>
                      {" "}
                      <Switch
                        checked={control[control_item]}
                        disabled={loader}
                        onChange={(e: any) => {
                          toggle_control(control_item, e.target.checked);
                        }}
                        color={control[control_item] ? "success" : "neutral"}
                        variant="solid"
                        endDecorator={control[control_item] ? "On" : "Off"}
                        slotProps={{
                          endDecorator: {
                            sx: {
                              minWidth: 24,
                            },
                          },
                        }}
                      />
                    </span>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </PageLayout>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
