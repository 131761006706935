import { Box, Grid, Paper } from "@mui/material";
import "chart.js/auto";
import moment from "moment";
import { Chart } from "react-chartjs-2";

var dynamicColors = function () {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

const plugin = {
  id: "custom_canvas_background_color",
  beforeDraw: (chart: any) => {
    const ctx = chart.canvas.getContext("2d");
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

function isSingleValue(yValues: any) {
  return (
    Object.keys(yValues).length === 1 &&
    yValues[Object.keys(yValues)[0]].length === 1
  );
}

function getSingleValueBlock(props: any) {
  const { xValues, yValues } = props;
  return (
    <Box m={1} p={1} borderRadius={5} bgcolor="#F5EDDE">
      <div>
        <span style={{ float: "right" }}></span>
      </div>
      <div style={{ fontSize: 16 }}>
        <div>{xValues[0]}</div>
        <div
          style={{
            fontSize: 22,
            display: "inline-flex",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          {yValues[Object.keys(yValues)[0]][0]}
        </div>
      </div>
    </Box>
  );
}

function getGraph(props: any) {
  const { type, xValues, yValues } = props;
  let updatedLocalTime: any = [];
  if (xValues) {
    for (let index = 0; index < xValues.length; index++) {
      updatedLocalTime.push(
        moment(moment.utc(xValues[index], "MM/DD/YY hh:mm a"))
          .local()
          .format("MM/DD/YY hh:mm a")
      );
    }
  }
  if (isSingleValue(yValues)) {
    return getSingleValueBlock(props);
  }
  let graphData: any = {};
  if (type === "Bar") {
    let graphKeys: any = Object.keys(yValues);
    let graphDataSet: any = [];
    for (let graphIndex = 0; graphIndex < graphKeys.length; graphIndex++) {
      graphDataSet.push(yValues[graphKeys[graphIndex]].toFixed(2));
    }
    graphData = {
      labels: graphKeys,
      datasets: [
        {
          label: "Hours Run",
          data: graphDataSet,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          grid: {
            display: false,
          },
        },
      ],
    };
  } else {
    graphData = {
      labels: updatedLocalTime,
      datasets: Array.from(Object.keys(yValues)).map((barLabel: any) => {
        const randomizedColor = dynamicColors();
        return {
          label: barLabel,
          backgroundColor: randomizedColor,
          borderColor: randomizedColor,
          borderWidth: 1,
          data: yValues[barLabel],
          grid: {
            display: false,
          },
        };
      }),
    };
  }

  if (type === "Bar") {
    return <Chart type="bar" data={graphData} plugins={[plugin]} />;
  } else if (type === "Line") {
    return <Chart type="line" data={graphData} plugins={[plugin]} />;
  }
}

function Graph(props: any) {
  const { blockTitle } = props;
  return (
    <Grid item xs={12} sm={6} md={6} lg={6} id="chart">
      <Box p={2}>
        <Paper variant="outlined" style={{ backgroundColor: "#FFFFFF" }}>
          <Box style={{ marginTop: "10px" }}>
            <div
              style={{
                fontFamily: "Montserrat SemiBold",
                fontSize: 20,
                opacity: "85%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {blockTitle}
            </div>
            {getGraph(props)}
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
}

export { Graph };
