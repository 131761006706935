import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FactoryIcon from "@mui/icons-material/Factory";
import { useNavigate } from "react-router-dom";
import { Collapse, Stack, Avatar, Paper } from "@mui/material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InventoryIcon from "@mui/icons-material/Inventory";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LogoutIcon from "@mui/icons-material/Logout";
import byte_flyt_logo_svg from "../Constants/Media/SVG/byte_flyt_logo.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HailIcon from "@mui/icons-material/Hail";
import PeopleIcon from "@mui/icons-material/People";
import TaskIcon from "@mui/icons-material/Task";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AddTaskIcon from '@mui/icons-material/AddTask';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props: any) {
  const theme = useTheme();
  const { page_heading } = props;
  const [open, setOpen] = React.useState(false);
  const [subMenu, setSubMenu] = React.useState(-1);
  const sideBarString: any = localStorage.getItem("byte_flyt_user_navbar")
    ? localStorage.getItem("byte_flyt_user_navbar")
    : "[]";
  const sideBar: any = JSON.parse(sideBarString);
  const user_info_string: any = localStorage.getItem("byte_flyt_user_info")
    ? localStorage.getItem("byte_flyt_user_info")
    : JSON.stringify({ name: "Unknown", email: "Unknown" });
  const user_info: any = JSON.parse(user_info_string);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const subMenuClick = (index: number) => {
    if (index === subMenu) {
      setSubMenu(-1);
    } else {
      setSubMenu(index);
    }
  };

  const returnIcon = (menuname: string) => {
    switch (menuname) {
      case "Dashboard":
        return <DashboardIcon />;
      case "Analytics":
        return <AnalyticsIcon />;
      case "Inventory":
        return <InventoryIcon />;
      case "Sites":
        return <FactoryIcon />;
      case "Clients":
        return <HailIcon />;
      case "ByteFlyt Admins":
        return <PeopleIcon />;
      case "Workforce":
        return <PeopleIcon />;
      case "My Tasks":
        return <TaskIcon />;
      case "Control":
        return <ToggleOnIcon />;
      case "Reports":
        return <InsertChartIcon/>
      case "Configure Tasks":
        return <AddTaskIcon/>
      default:
        return <FactoryIcon />;
    }
  };

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const Logout = () => {
    let byte_flty_admin = false;
    if (localStorage.byte_flyt_admin) {
      byte_flty_admin = localStorage.byte_flyt_admin === "true";
    }

    sessionStorage.clear();
    localStorage.clear();
    if (byte_flty_admin) {
      //@ts-ignore
      window.location = "/byteflyt/login";
    } else {
      //@ts-ignore
      window.location = "/";
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const styles = {
    // this group of buttons will be aligned to the right side
    toolbarButtons: {
      marginLeft: "auto",
    },
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: "#046499" }}>
        <Stack direction="row">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <img
            src={byte_flyt_logo_svg}
            height="90%"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
              paddingTop: 2,
              paddingBottom: 2,
            }}
          />
        </Stack>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          top: 0,
          height: "100%",
          position: "fixed",
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sideBar.map((menu: any, index: any) =>
            menu.children ? (
              <>
                <ListItem
                  key={menu.name}
                  disablePadding
                  onClick={(e) => {
                    subMenuClick(index);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <FactoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={menu.name} style={{width:"100%"}} />
                    <ListItemIcon style={{ marginLeft: 135 }}>
                      {subMenu === index ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <Collapse in={subMenu === index} timeout="auto" unmountOnExit>
                  <div style={{ marginLeft: 20 }}>
                    {menu.children.map(
                      (subMenuOption: any, subMenuIndex: any) => (
                        <ListItem
                          key={menu.name}
                          disablePadding
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(subMenuOption.link);
                            handleDrawerClose();
                          }}
                        >
                          <ListItemButton>
                            <ListItemIcon>
                              {returnIcon(subMenuOption.name)}
                            </ListItemIcon>
                            <ListItemText primary={subMenuOption.name} />
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                  </div>
                </Collapse>
                <Divider />
              </>
            ) : (
              <>
                <ListItem
                  key={menu.name}
                  disablePadding
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(menu.link);
                    handleDrawerClose();
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>{returnIcon(menu.name)}</ListItemIcon>
                    <ListItemText primary={menu.name} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )
          )}
        </List>
        <List
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: drawerWidth,
          }}
        >
          <Divider />
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Avatar {...stringAvatar(user_info.name)} />
              </ListItemIcon>
              <ListItemText>
                <div>
                  <div style={{ fontSize: 14, fontWeight: 700 }}>
                    {user_info.name}
                  </div>
                  <div style={{ fontSize: 10 }}>{user_info.email}</div>
                </div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding onClick={Logout}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>
                <div style={{ fontSize: 14, fontWeight: 700 }}>Logout</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  );
}
