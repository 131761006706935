import { Grid, Paper } from "@mui/material";

export default function PageLayout(props: any) {
    const {heading} = props;
    return(
        <div>
        <div style={{ top:"8%", width:"100%"}}>
        <Grid container spacing={2}>
          <Grid item xs={1} sm={4} lg={4} xl={5}></Grid>
          <Grid item xs={10} sm={4} lg={4} xl={2}>
            <Paper
              elevation={5}
              style={{
                backgroundColor: "#5DB0E0",
                color: "#FFFFFF",
                fontWeight: 900,
                fontSize: 28,
                margin: 5,
                padding: 5,
                alignItems: "middle"
              }}
            >
              <div style={{display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'}}>
              {heading}
              </div>
              
            </Paper>
          </Grid>
          <Grid item xs={1} sm={4} lg={4} xl={5}></Grid>
        </Grid>
        </div>
        <div style={{ marginTop:-30,width:"100%"}}>
          <Paper elevation={5} style={{ margin:5, padding: 5}}><div style={{marginTop:20}}>{props.children}</div></Paper>
        </div>
        </div>
    )
}