import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import DownloadIcon from "@mui/icons-material/Download";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import dayjs from "dayjs";
import { Graph } from "../../Components/graph";
import jsPDF from "jspdf";

export default function Reports() {
  const [loader, set_loader] = useState(false);
  const [from_time, set_from_time] = useState(
    dayjs(moment().startOf("day").format())
  );
  const [to_time, set_to_time] = useState(dayjs(moment().format()));
  const [report_data, set_report_data]: any = useState({});
  const [date, set_date] = useState([]);
  const [refresh_report_data, set_refresh_report_data] = useState(false);
  const { id } = useParams();
  const [expanded, set_expanded]: any = useState({});

  useEffect(() => {
    set_loader(true);
    get_report_data();
  }, [id]);

  const get_report_data = () => {
    set_loader(true);
    AxiosInstance()
      .get(`/sites/reports/${id}?from=${from_time.unix()}&to=${to_time.unix()}`)
      .then(({ data }: any) => {
        set_report_data(data.sensorData.sensorData);
        set_date(data.sensorData.date);
        set_loader(false);
        set_refresh_report_data(!refresh_report_data);
        let sections = Object.keys(data.sensorData.sensorData);
        let temp_accordian_list: any = {};
        for (let i = 0; i < sections.length; i++) {
          temp_accordian_list[sections[i]] = true;
        }
        set_expanded(temp_accordian_list);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  const getDifferenceInHours = (date1: any, date2: any) => {
    const diffInMs = date2 - date1;
    return diffInMs / (1000 * 60 * 60);
  };

  const getReport = () => {
    if (from_time > dayjs() || to_time > dayjs()) {
      toast.error(
        `Cannot predict the future, please select a valid time range`
      );
    } else {
      let timedifference = getDifferenceInHours(from_time, to_time);
      if (timedifference < 0) {
        toast.error("To Date cannot be before From date.");
      } else if (timedifference < 1) {
        toast.error(
          "Minimum 1 hour difference should be provided between dates, to Plot Reports."
        );
      } else {
        get_report_data();
        toast.success("Quering Reports");
      }
    }
  };

  const handle_accordian_change = (section_name: any) => {
    let current_value = expanded;
    current_value[section_name] = !current_value[section_name];
    console.log(current_value);
    set_expanded(current_value);
    set_refresh_report_data(!refresh_report_data);
  };

  const download_report = () => {
    if (Object.keys(report_data).length === 0) {
      toast.error(
        "No Data available in the Time Span, cannot download the report"
      );
    } else {
      const reportElement = document.getElementById("Report_Cover");
      if (reportElement !== null) {
        let width = reportElement.offsetWidth;
        let height = reportElement.offsetHeight;

        var doc = new jsPDF("p", "mm", [width + 30, height + 50]);
        doc.html(reportElement, {
          callback: function (pdf: any) {
            pdf.save("Report.pdf");
          },
          x: 10,
          y: 10,
        });
      } else {
        toast.error(
          "Failed to download the report, Please refresh and try again."
        );
      }
    }
  };

  return (
    <Navbar>
      <PageLayout heading="Reports">
        <div style={{ margin: 10, padding: 10 }}>
          <Paper
            elevation={5}
            style={{ borderRadius: 10, margin: 5, padding: 5 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["MobileDateTimePicker"]}>
                  <MobileDateTimePicker
                    disableFuture
                    label="From"
                    value={from_time}
                    onChange={(newValue: any) => {
                      set_from_time(newValue);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <div style={{ marginLeft: 10 }}></div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["MobileDateTimePicker"]}>
                  <MobileDateTimePicker
                    disableFuture
                    label="Till"
                    value={to_time}
                    onChange={(newValue: any) => {
                      set_to_time(newValue);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Button
                style={{ width: "wrap", margin: 5 }}
                variant="contained"
                disabled={loader}
                onClick={getReport}
              >
                Get Report <AnalyticsIcon />
              </Button>
              <Button
                style={{ width: "wrap", margin: 5 }}
                variant="contained"
                disabled={loader || Object.keys(report_data).length === 0}
                onClick={download_report}
              >
                Download <DownloadIcon />
              </Button>
            </div>
          </Paper>
          <Paper
            elevation={5}
            style={{ borderRadius: 10, margin: 5, padding: 5 }}
            id="Report_Cover"
          >
            {Object.keys(report_data).length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 20,
                  fontSize: 20,
                  fontWeight: 800,
                }}
              >
                No Data available within given time span
              </div>
            ) : null}
            {Object.keys(report_data).map((section: any) => {
              return (
                <Box p={2} border={0.2} borderRadius={5} width="100%">
                  <h3>{section}</h3>
                  <Grid container>
                    {Object.keys(report_data[section]).map((graph: any) => {
                      if (graph === "Equipment Hours Run") {
                        return (
                          <Graph
                            type="Bar"
                            blockTitle={graph}
                            yValues={report_data[section][graph]}
                          />
                        );
                      }
                      return (
                        <Graph
                          type="Line"
                          blockTitle={graph}
                          xValues={date}
                          yValues={report_data[section][graph]}
                        />
                      );
                    })}
                  </Grid>
                </Box>
              );
            })}
            <Box p={2} border={0.2} borderRadius={5} width="100%">
              <h3>Consolidated Report</h3>
              <Grid container>
                {report_data &&
                report_data["Equipment Performance"] &&
                report_data["Equipment Performance"]["Equipment Hours Run"] ? (
                  <Grid item xs={12} sm={6} md={6} lg={6} id="chart">
                    <Box p={2}>
                      <Paper
                        variant="outlined"
                        style={{ backgroundColor: "#FFFFFF" }}
                      >
                        <Box style={{ marginTop: "10px" }}>
                          <div
                            style={{
                              fontFamily: "Montserrat SemiBold",
                              fontSize: 20,
                              opacity: "85%",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Equipment Hours Run
                          </div>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Equipment Name</TableCell>
                                <TableCell>Hours Run</TableCell>
                              </TableRow>
                              {Object.keys(
                                report_data["Equipment Performance"][
                                  "Equipment Hours Run"
                                ]
                              ).map((equipment: any) => {
                                return (
                                  <TableRow>
                                    <TableCell>{equipment}</TableCell>
                                    <TableCell>
                                      {report_data["Equipment Performance"][
                                        "Equipment Hours Run"
                                      ][equipment].toFixed(1)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableHead>
                          </Table>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                ) : null}
                {/* 
                <Grid item xs={12} sm={6} md={6} lg={6} id="chart">
                  <Box p={2}>
                    <Paper
                      variant="outlined"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      <Box style={{ marginTop: "10px" }}>
                        <div
                          style={{
                            fontFamily: "Montserrat SemiBold",
                            fontSize: 20,
                            opacity: "85%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Plant Performace
                        </div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Equipment Name</TableCell>
                              <TableCell>Hours Run</TableCell>
                            </TableRow>
                            {Object.keys(report_data["Equipment Performance"]["Equipment Hours Run"]).map(
                              (equipment: any) => {
                                return (
                                  <TableRow>
                                    <TableCell>{equipment}</TableCell>
                                    <TableCell>
                                      {report_data["Equipment Performance"]["Equipment Hours Run"][equipment]}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableHead>
                        </Table>
                      </Box>
                    </Paper>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
            {/* {Object.keys(report_data).map((section: any) => {
              return (
                <Accordion
                  expanded={expanded[section]}
                  onChange={() => {
                    handle_accordian_change(section);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    {section}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {Object.keys(report_data[section]).map((graph: any) => {
                        if (graph === "Equipment Hours Run") {
                          return (
                            <Graph
                              type="Bar"
                              blockTitle={graph}
                              yValues={report_data[section][graph]}
                            />
                          );
                        }
                        return (
                          <Graph
                            type="Line"
                            blockTitle={graph}
                            xValues={date}
                            yValues={report_data[section][graph]}
                          />
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })} */}
          </Paper>
        </div>
      </PageLayout>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
