import { useEffect, useState } from "react";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import toast, { Toaster } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import WarehouseIcon from "@mui/icons-material/Warehouse";

export default function ByteflytDashboard() {
  const [loader, set_loader] = useState(false);
  const [total_clients, set_total_clients] = useState(0);
  const [total_sites, set_total_sites] = useState(0);
  const [new_sites, set_new_sites] = useState(0);
  const [expiring_sites, set_expiring_sites] = useState(0);

  useEffect(() => {
    set_loader(true);
    AxiosInstance()
      .get("/byteflyt/dashboard")
      .then(({ data }: any) => {
        set_loader(false);
        console.log(data);
        set_total_clients(data.client_count);
        set_total_sites(data.site_count);
        set_new_sites(data.sites_not_started_count);
        set_expiring_sites(data.expiring_soon_count);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, []);
  return (
    <Navbar>
      <PageLayout heading="Dashboard">
        <div style={{ margin: 2, padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#95BDFF" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <PeopleAltIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Total Clients
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {total_clients}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#F7C8E0" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <WarehouseIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Total Sites
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {total_sites}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#90EE90" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <UpcomingIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Upcoming Sites
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {new_sites}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Card style={{ background: "#FFCCCB" }}>
                <CardContent style={{ alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BrowseGalleryIcon fontSize="large" />
                    </div>
                    <div
                      style={{
                        margin: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        Expiring/Expired Sites
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h4" component="h2">
                      {expiring_sites}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </PageLayout>
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <Toaster />
    </Navbar>
  );
}
