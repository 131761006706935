import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import HistoryIcon from "@mui/icons-material/History";

export default function ConfigureTasks() {
  const [loader, set_loader] = useState(false);
  const [tasks, set_tasks] = useState([]);
  const [allowed_tasks, set_allowed_tasks] = useState(0);
  const [active_tasks, set_active_tasks] = useState(0);
  const [anchorElUser, setAnchorElUser]: any = useState(null);
  const [user_info, set_user_info]: any = useState({});
  const [add_task_popup, set_add_task_popup]: any = useState(false);
  const [edit_task, set_edit]: any = useState(false);
  const [refresh, set_refresh] = useState(false);
  const [refresh_screen, set_refresh_screen] = useState(false);
  const [new_task, set_new_task]: any = useState({});
  const [active_users, set_active_users]: any = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    set_loader(true);
    set_user_info(false);
    set_add_task_popup(false);
    set_edit(false);
    setAnchorElUser(null);
    AxiosInstance()
      .get(`/sites/tasks/${id}`)
      .then(({ data }: any) => {
        set_tasks(data.tasks);
        set_allowed_tasks(data.allowed_tasks);
        set_active_tasks(data.active_tasks);
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh, id]);

  const edit_task_click = (task: any) => {
    let edit_task: any = {};
    Object.assign(edit_task, task);
    edit_task.task_time = dayjs()
      .startOf("date")
      .add(task.task_time, "minutes");
    AxiosInstance()
      .get("/sites/active_users")
      .then(({ data }: any) => {
        set_active_users(data.active_users);
        let temp_active_users = data.active_users;
        let remove_assigned_user = true;
        for (let i = 0; i < temp_active_users.length; i++) {
          if (temp_active_users[i]["_id"] === task.assigned_to) {
            remove_assigned_user = false;
            break;
          }
        }
        if (remove_assigned_user) {
          delete edit_task.assigned_to;
        }
        delete edit_task.task_suspended;
        delete edit_task.user_info;
        delete edit_task.task_status;
        set_loader(false);
        set_edit(true);
        set_add_task_popup(true);
        set_new_task(edit_task);
      })
      .catch((err: any) => {
        toast.error("Failed to fetch active users");
        toast.error(err.message);
        set_loader(false);
      });
  };
  const add_new_task_click = () => {
    if (active_tasks >= allowed_tasks) {
      toast.error(
        `Can not add a new Task. Max account limit reached: ${allowed_tasks}`
      );
    } else {
      set_loader(true);
      AxiosInstance()
        .get("/sites/active_users")
        .then(({ data }: any) => {
          set_active_users(data.active_users);
          set_loader(false);
          set_add_task_popup(true);
          set_edit(false);
          set_new_task({
            task_freq: "Daily",
            task_time: dayjs().startOf("date"),
          });
        })
        .catch((err: any) => {
          toast.error("Failed to fetch active users");
          toast.error(err.message);
          set_loader(false);
        });
    }
  };

  const week_days_map: any = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  const add_day = (day: any) => {
    let temp_new_task: any = new_task;
    let day_map: any = day;
    if (temp_new_task.task_freq === "Weekly") {
      day_map = week_days_map[day];
    }
    if (temp_new_task.days.indexOf(day_map) === -1) {
      temp_new_task.days.push(day_map);
    } else {
      temp_new_task.days.splice(temp_new_task.days.indexOf(day_map), 1);
    }
    set_new_task(temp_new_task);
    set_refresh_screen(!refresh_screen);
  };
  const week_days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handle_open_user_info = (
    event: React.MouseEvent<HTMLElement>,
    info: any
  ) => {
    set_user_info(info);
    setAnchorElUser(event.currentTarget);
  };
  const handle_close_user_info = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(null);
  };

  const update_new_task = (name: string, value: any) => {
    let temp_task = new_task;
    temp_task[name] = value;
    if (name === "task_freq") {
      if (value === "Monthly" || value === "Weekly") {
        temp_task["days"] = [];
      } else {
        delete temp_task["days"];
      }
    }
    if (name === "deadline") {
      value = parseInt(value);
    }

    set_new_task(temp_task);
    set_refresh_screen(!refresh_screen);
  };

  const handle_update_create_call = () => {
    if (
      new_task.task_description &&
      new_task.task_description &&
      new_task.assigned_to
    ) {
      if (
        (new_task.task_freq === "Weekly" || new_task.task_freq === "Monthly") &&
        new_task.days.length === 0
      ) {
        toast.error("Please select atleast 1 day to schedule the task");
      } else {
        if (edit_task) {
          edit_task_call();
        } else {
          create_task_call();
        }
      }
    } else {
      toast.error("Provide all fields!!");
    }
  };

  const edit_task_call = () => {
    let task_info: any = new_task;
    task_info.task_time = task_info.task_time.diff(
      dayjs().startOf("date"),
      "minutes"
    );
    AxiosInstance()
      .put(`/sites/tasks/${id}`, task_info)
      .then(({ data }: any) => {
        toast.success("Task Added Successfully");
        set_refresh(!refresh);
      })
      .catch((err: any) => {
        toast.error("Failed to Add Task");
        toast.error(err.message);
        set_loader(false);
      });
  };
  const create_task_call = () => {
    let task_info: any = new_task;
    task_info.task_time = task_info.task_time.diff(
      dayjs().startOf("date"),
      "minutes"
    );
    AxiosInstance()
      .post(`/sites/tasks/${id}`, task_info)
      .then(({ data }: any) => {
        toast.success("Task Added Successfully");
        set_refresh(!refresh);
      })
      .catch((err: any) => {
        toast.error("Failed to Add Task");
        toast.error(err.message);
        set_loader(false);
      });
  };

  const toggleTask = (task_id: any, task_status: any) => {
    set_loader(true);
    AxiosInstance()
      .put(`/sites/task_toggle/${id}`, {
        status: task_status,
        task_id: task_id,
      })
      .then(({ data }: any) => {
        toast.success("Task Toggled Successfully");
        set_refresh(!refresh);
      })
      .catch((err: any) => {
        toast.error("Failed to Toggle Task");
        toast.error(err.message);
        set_loader(false);
      });
  };

  return (
    <Navbar>
      <PageLayout heading="Configure Tasks">
        <p style={{ fontSize: 16, fontWeight: 600, margin: 20 }}>
          <span>Allowed Tasks: {allowed_tasks}</span>
          <span style={{ float: "right" }}>Active Tasks: {active_tasks}</span>
        </p>
        <div style={{ margin: 10, padding: 10 }}>
          {tasks.map((task: any) => {
            let color = "#FFFFFF";
            if (task.task_suspended) {
              color = "#FFCCCB";
            }
            return (
              <Paper
                elevation={5}
                style={{ margin: 5, padding: 10, background: color }}
              >
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={8} md={8} lg={10} xl={11}>
                    <span
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                        color: "#046499",
                        justifyContent: "center",
                      }}
                    >
                      {task.task_title}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2} xl={1}>
                    <Tooltip title="Edit Task">
                      <IconButton
                        disabled={loader}
                        onClick={() => {
                          edit_task_click(task);
                        }}
                      >
                        <EditIcon style={{ color: "black" }} />
                      </IconButton>
                    </Tooltip>
                    {task.task_suspended ? (
                      <Tooltip title="Activate Task">
                        <IconButton
                          disabled={loader}
                          onClick={() => {
                            toggleTask(task._id, false);
                          }}
                        >
                          <DoneIcon style={{ color: "green" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Suspend Task">
                        <IconButton
                          disabled={loader}
                          onClick={() => {
                            toggleTask(task._id, true);
                          }}
                        >
                          <BlockIcon style={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Task History">
                      <IconButton
                        onClick={() => {
                          navigate(`/task_history/${task._id}`);
                        }}
                      >
                        <HistoryIcon style={{ color: "balck" }} />
                      </IconButton>
                    </Tooltip>
                    {task.user_info.account_deactivated ? (
                      <Tooltip title="This task has been deactivate because, the user associated with this account has been deactivated. Either Activate the user account or change the assigned user and then activate the task">
                        <IconButton>
                          <InfoIcon style={{ color: "balck" }} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={10} xl={11}>
                    <div style={{ margin: 5 }}>
                      <span style={{ fontWeight: 600, fontSize: 16 }}>
                        Task Frequency: {task.task_freq}
                      </span>
                    </div>
                    {task.task_freq === "Weekly" ? (
                      <>
                        {week_days.map((day: any, i) => {
                          return (
                            <IconButton
                              disabled
                              style={{
                                background:
                                  task.days.indexOf(i + 1) !== -1
                                    ? "green"
                                    : "white",
                                fontSize: 12,
                                margin: 3,
                              }}
                            >
                              <>{day}</>
                            </IconButton>
                          );
                        })}
                      </>
                    ) : null}
                    {task.task_freq === "Monthly" ? (
                      <>
                        {Array.from(Array(31).keys()).map((day: any, i) => {
                          return (
                            <IconButton
                              disabled
                              style={{
                                background:
                                  task.days.indexOf(i + 1) !== -1
                                    ? "green"
                                    : "white",
                                fontSize: 12,
                                margin: 3,
                              }}
                            >
                              <>{day + 1}</>
                            </IconButton>
                          );
                        })}
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2} xl={1}>
                    <span style={{ fontWeight: 600, fontSize: 16, margin: 5 }}>
                      Task Time:{" "}
                      {moment()
                        .startOf("day")
                        .minutes(task.task_time)
                        .format("hh:mm a")}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={10} xl={11}>
                    <div style={{ margin: 5 }}>
                      <span style={{ fontWeight: 600, fontSize: 16 }}>
                        Task Description:
                      </span>
                    </div>
                    <div
                      style={{
                        wordWrap: "break-word",
                        fontSize: 12,
                        margin: 5,
                      }}
                    >
                      {task.task_description}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2} xl={1}>
                    <div style={{ margin: 5 }}>
                      <span style={{ fontWeight: 600, fontSize: 16 }}>
                        Assigned To:{" "}
                        <Tooltip title="Click for User Information">
                          <span
                            style={{
                              color: "#046499",
                              fontWeight: 800,
                            }}
                            onClick={(event) => {
                              handle_open_user_info(event, task.user_info);
                            }}
                          >
                            {task.user_info.name}
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                    {task.deadline ? (
                      <div style={{ margin: 5 }}>
                        <span style={{ fontWeight: 600, fontSize: 16 }}>
                          Deadline:
                        </span>{" "}
                        {task.deadline} mins
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </div>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handle_close_user_info}
        >
          <div style={{ margin: 10 }}>
            <div>
              <strong>Name: </strong>
              {user_info.name}
            </div>
            <div>
              <strong>Email: </strong>
              {user_info.email}
            </div>
            <div>
              <strong>Number: </strong>
              {user_info.phone_number}
            </div>
          </div>
        </Menu>
      </PageLayout>
      <Modal
        open={add_task_popup}
        onClose={() => {
          set_add_task_popup(false);
        }}
        style={{ marginTop: "10%", width: "30%", marginLeft: "35%" }}
      >
        <Paper elevation={5} style={{ height: "80%", overflowX: "scroll" }}>
          <div style={{ padding: 10 }}>
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Title"
              variant="outlined"
              type="text"
              value={new_task.task_title}
              onChange={(e) => {
                update_new_task("task_title", e.target.value);
              }}
              disabled={loader}
            />
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Description"
              multiline
              variant="outlined"
              type="text"
              value={new_task.task_description}
              onChange={(e) => {
                update_new_task("task_description", e.target.value);
              }}
              disabled={loader}
            />
            <FormControl style={{ width: "90%" }}>
              <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={new_task.task_freq}
                onChange={(e) => {
                  update_new_task("task_freq", e.target.value);
                }}
                label="Frequency"
              >
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                <MenuItem value="Monthly">Montly</MenuItem>
              </Select>
            </FormControl>
            {new_task.task_freq === "Weekly" ? (
              <div>
                {week_days.map((day: any, i) => {
                  return (
                    <IconButton
                      disabled={loader}
                      style={{
                        background:
                          new_task.days.indexOf(i + 1) !== -1
                            ? "green"
                            : "white",
                        fontSize: 12,
                        margin: 3,
                      }}
                      onClick={() => {
                        add_day(day);
                      }}
                    >
                      <>{day}</>
                    </IconButton>
                  );
                })}
              </div>
            ) : null}

            {new_task.task_freq === "Monthly" ? (
              <div>
                {Array.from(Array(31).keys()).map((day: any, i) => {
                  return (
                    <IconButton
                      disabled={loader}
                      style={{
                        background:
                          new_task.days.indexOf(i + 1) !== -1
                            ? "green"
                            : "white",
                        fontSize: 12,
                        margin: 3,
                      }}
                      onClick={() => {
                        add_day(day + 1);
                      }}
                    >
                      <>{day + 1}</>
                    </IconButton>
                  );
                })}
              </div>
            ) : null}
            <div style={{ width: "90%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
              >
                <DemoItem label="Task Time">
                  <MobileTimePicker
                    defaultValue={new_task.task_time}
                    onChange={(e) => {
                      update_new_task("task_time", e);
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
            </div>
            <FormControl style={{ width: "90%", marginTop: 10 }}>
              <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={new_task.assigned_to}
                onChange={(e) => {
                  update_new_task("assigned_to", e.target.value);
                }}
                label="Assigned To"
              >
                {active_users.map((user: any) => {
                  return <MenuItem value={user._id}>{user.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              style={{
                width: "90%",
                marginTop: 10,
                marginBottom: 10,
                fontSize: 10,
              }}
              id="outlined-basic"
              label="Deadline (in minutes)"
              multiline
              variant="outlined"
              type="number"
              value={new_task.deadline}
              onChange={(e) => {
                update_new_task("deadline", e.target.value);
              }}
              disabled={loader}
            />
            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "90%",
              }}
              disabled={loader}
              onClick={handle_update_create_call}
            >
              {edit_task ? <>Update Task</> : <>Create Task</>}
            </Button>
          </div>
        </Paper>
      </Modal>
      <Tooltip title="Add New Task">
        <IconButton
          style={{
            backgroundColor: "#046499",
            position: "fixed",
            bottom: 15,
            right: 15,
          }}
          onClick={add_new_task_click}
        >
          <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
        </IconButton>
      </Tooltip>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
