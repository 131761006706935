import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";

export default function Dashboard() {
  const [tooltipContent, setTooltipContent] = useState("");
  const [sites, set_sites] = useState([
    {
      site_name: "Ireo Uptown",
      lat_long: [],
    },
  ]);

  const [markerData, setMarkerData] = useState([
    { name: 'Delhi', coordinates: [28.6139, 77.2090] },
    { name: 'Mumbai', coordinates: [19.0760, 72.8777] },
    { name: 'Bangalore', coordinates: [12.9716, 77.5946] },
  ]);

  const handleMarkerClick = (marker:any) => {
    console.log(marker.name);
  };


  return (
    <Navbar>
      <PageLayout></PageLayout>
    </Navbar>
  );
}
