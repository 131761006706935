import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import moment from "moment";
import { Button, Menu, Paper, Tooltip } from "@mui/material";

export default function Inventory_History() {
  const [history, set_history] = useState([]);
  const [loader, set_loader] = useState(false);
  const [anchorElUser, setAnchorElUser]: any = useState(null);
  const [user_info, set_user_info]: any = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    set_loader(true);
    setAnchorElUser(null);
    set_user_info({});
    AxiosInstance()
      .get(`/sites/inventory_history/${id}`)
      .then(({ data }: any) => {
        console.log(data);
        set_history(data.history);
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [id]);

  const handle_open_user_info = (
    event: React.MouseEvent<HTMLElement>,
    info: any
  ) => {
    set_user_info(info);
    setAnchorElUser(event.currentTarget);
  };
  const handle_close_user_info = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(null);
  };

  return (
    <Navbar>
      <PageLayout heading="Inventory History">
        <div style={{ margin: 2, padding: 2 }}>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/inventory/${id}`);
            }}
            style={{ background: "#046499", color: "#FFFFFF" }}
          >
            Back
          </Button>
        </div>
        <div style={{ margin: 2, padding: 2 }}>
          <Timeline position="alternate">
            {history.map((item: any) => {
              return (
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    {moment(item.timestamp).format("DD/MM/YYYY hh:mm A")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    {item.type === "Updation" ? (
                      <TimelineDot color="secondary" />
                    ) : (
                      <TimelineDot color="primary" />
                    )}
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={5} style={{ padding: 10 }}>
                      {item.type === "Updation" ? (
                        <div>
                          <div
                            style={{
                              fontWeight: 600,
                              fontSize: 16,
                              marginLeft: 5,
                            }}
                          >
                            Inventory Stock Updated by{" "}
                            <Tooltip title="Click for User Information">
                              <span
                                style={{
                                  color: "#046499",
                                  fontWeight: 800,
                                }}
                                onClick={(event) => {
                                  handle_open_user_info(event, item.user_info);
                                }}
                              >
                                {item.user_info.name}
                              </span>
                            </Tooltip>
                          </div>
                          {Object.keys(item.data).map((invent) => {
                            return (
                              <div>
                                <strong>
                                  {invent}
                                  {item.data[invent] > 0
                                    ? "Stocked"
                                    : "Used"} :{" "}
                                </strong>
                                {item.data[invent] > 0
                                  ? item.data[invent]
                                  : item.data[invent] * -1}{" "}
                                Qty
                              </div>
                            );
                          })}

                          {item.remarks ? (
                            <div>
                              <p></p>
                              <strong>Remarks : </strong>
                              {item.remarks}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <>
                          {item.type === "Deletion" ? (
                            <div>
                              <div style={{ fontWeight: 600, fontSize: 16 }}>
                                Inventory Item Deleted by{" "}
                                <Tooltip title="Click for User Information">
                                  <span
                                    style={{
                                      color: "#046499",
                                      fontWeight: 800,
                                    }}
                                    onClick={(event) => {
                                      handle_open_user_info(
                                        event,
                                        item.user_info
                                      );
                                    }}
                                  >
                                    {item.user_info.name}
                                  </span>
                                </Tooltip>
                              </div>
                              <div>Item Name: {item.data.name}</div>
                              <div>Last Stock: {item.data.last_value} Qty</div>
                            </div>
                          ) : (
                            <div>
                              <div style={{ fontWeight: 600, fontSize: 16 }}>
                                Inventory Item Added by{" "}
                                <Tooltip title="Click for User Information">
                                  <span
                                    style={{
                                      color: "#046499",
                                      fontWeight: 800,
                                    }}
                                    onClick={(event) => {
                                      handle_open_user_info(
                                        event,
                                        item.user_info
                                      );
                                    }}
                                  >
                                    {item.user_info.name}
                                  </span>
                                </Tooltip>
                              </div>
                              <div>Item Added: {item.data.name}</div>
                              <div>
                                Minimum Quantity: {item.data.min_quantity} Qty
                              </div>
                              <div>Quantity: {item.data.quantity} Qty</div>
                            </div>
                          )}
                        </>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handle_close_user_info}
        >
          <div style={{ margin: 10 }}>
            <div>
              <strong>Name: </strong>
              {user_info.name}
            </div>
            <div>
              <strong>Email: </strong>
              {user_info.email}
            </div>
            <div>
              <strong>Number: </strong>
              {user_info.phone_number}
            </div>
          </div>
        </Menu>
      </PageLayout>

      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
