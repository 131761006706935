import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Dna } from "react-loader-spinner";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import { toast, Toaster } from "react-hot-toast";
import AxiosInstance from "../../Components/axiosinstance";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import BlockIcon from "@mui/icons-material/Block";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

export default function Clients() {
  const [loader, set_loader] = useState(false);
  const [add_client, set_add_client] = useState(false);
  const [update_client, set_update_client] = useState(true);
  const [client_to_update, set_client_to_update]: any = useState({});
  const [clients, set_clients] = useState([]);
  const [refresh_data, set_refresh_data] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    set_loader(true);
    AxiosInstance()
      .get("/byteflyt/client")
      .then(({ data }) => {
        console.log(data);
        set_clients(data.clients);
        set_loader(false);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh_data]);

  const add_new_client_popup = () => {
    set_add_client(true);
    set_update_client(false);
    set_client_to_update({});
  };

  const update_client_popup = (client_details: any) => {
    set_add_client(true);
    set_update_client(true);
    set_client_to_update({
      _id: client_details._id,
      name: client_details.name,
      email: client_details.email,
      phone_number: client_details.phone_number,
    });
  };

  function stringAvatar(name: string) {
    let first_letter = "";
    let second_letter = "";
    if (name.indexOf(" ") === -1) {
      first_letter = name[0];
    } else {
      first_letter = name.split(" ")[0][0];
      second_letter = name.split(" ")[1][0];
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${first_letter}${second_letter}`,
    };
  }

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const toggleAccount = (client_id: any, status: any, client_name: any) => {
    set_loader(true);
    AxiosInstance()
      .post(`/byteflyt/toggle_client_account`, {
        _id: client_id,
        status: status,
      })
      .then(({ data }) => {
        if (status) {
          toast.success(`${client_name}: Account Suspended`);
        } else {
          toast.success(`${client_name}: Account Activated`);
        }

        set_loader(false);
        set_refresh_data(!refresh_data);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  const client_columns: any = [
    {
      headerName: "Name",
      width: 200,
      field: "name",
      renderCell: (params: any) => {
        return (
          <div
            className="rowitem"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/client_details/${params.row._id}`);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar {...stringAvatar(params.row.name)} />
              <div style={{ marginLeft: 5 }}> {params.row.name} </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "account_status",
      headerName: "Account Status",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            {params.row.account_deactivated ? <>Suspended</> : <>Active</>}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone_number",
      headerName: "Number",
      width: 200,
    },
    {
      field: "client_count.client_sites",
      headerName: "Sites",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">{params.row.client_count.client_sites}</div>
        );
      },
    },
    {
      headerName: "Actions",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={
                  params.row.account_deactivated
                    ? "Activate Account"
                    : "Suspend Account"
                }
              >
                <IconButton
                  style={{ margin: 5 }}
                  onClick={() => {
                    toggleAccount(
                      params.row._id,
                      !params.row.account_deactivated,
                      params.row.name
                    );
                  }}
                >
                  {params.row.account_deactivated ? (
                    <DoneIcon style={{ color: "green" }} />
                  ) : (
                    <BlockIcon style={{ color: "red" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Client Deatils">
                <IconButton
                  style={{ margin: 5 }}
                  onClick={() => {
                    update_client_popup(params.row);
                  }}
                >
                  <EditIcon style={{ color: "#5DB0E0" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const handle_client_popup = () => {
    // To Check if all fields are correct
    let valid_fields = true;
    if (client_to_update.name) {
      if (client_to_update.name.length === 0) {
        valid_fields = false;
        toast.error("Please provide Client name.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide Client name.");
    }

    if (client_to_update.email) {
      if (client_to_update.email.length === 0) {
        valid_fields = false;
        toast.error("Please provide Client email.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide Client email.");
    }

    if (client_to_update.phone_number) {
      if (client_to_update.phone_number.length !== 10) {
        valid_fields = false;
        toast.error("Please provide valid Client number.");
      }
    } else {
      valid_fields = false;
      toast.error("Please provide valid Client number.");
    }

    if (valid_fields) {
      if (update_client) {
        update_client_call();
      } else {
        create_client();
      }
    }
  };

  const update_client_call = () => {
    set_loader(true);
    AxiosInstance()
      .put(`/byteflyt/client`, client_to_update)
      .then(({ data }) => {
        set_loader(false);
        toast.success("Client updated successfully");
        set_add_client(false);
        set_refresh_data(!refresh_data);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };
  const create_client = () => {
    set_loader(true);
    AxiosInstance()
      .post("/byteflyt/client", client_to_update)
      .then(({ data }) => {
        set_loader(false);
        toast.success("Client added successfully");
        set_add_client(false);
        set_refresh_data(!refresh_data);
      })
      .catch((err) => {
        toast.error(err.message);
        set_loader(false);
      });
  };

  const update_client_value = (field: any, value: any) => {
    set_client_to_update({ ...client_to_update, [field]: value });
  };

  return (
    <Navbar>
      <PageLayout heading="Clients">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 2,
            padding: 2,
            height: 650,
            width: "95%",
          }}
        >
          <DataGrid
            style={{ overflowY: "scroll" }}
            getRowId={(row: any) => row._id}
            rows={clients}
            columns={client_columns}
          />
        </div>
      </PageLayout>
      <Tooltip title="Add New Client">
        <IconButton
          style={{
            backgroundColor: "#046499",
            position: "fixed",
            bottom: 15,
            right: 15,
          }}
          onClick={add_new_client_popup}
        >
          <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={add_client}
        onClose={() => {
          set_add_client(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                fontSize: 24,
                fontWeight: 700,
                color: "#046499",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {update_client ? <>Update Client</> : <>Add New Client</>}
            </div>
            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "100%",
              }}
              onClick={handle_client_popup}
              disabled={loader}
            >
              {update_client ? <>Update Client</> : <>Create Client</>}
            </Button>
          </div>
          <DialogContent>
            <DialogContentText>
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Client Name"
                variant="outlined"
                value={client_to_update.name}
                disabled={loader}
                onChange={(e) => update_client_value("name", e.target.value)}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Client Email"
                variant="outlined"
                value={client_to_update.email}
                disabled={loader}
                onChange={(e) => update_client_value("email", e.target.value)}
              />
              <TextField
                style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                id="outlined-basic"
                label="Client Number"
                variant="outlined"
                value={client_to_update.phone_number}
                disabled={loader}
                type="number"
                onChange={(e) =>
                  update_client_value("phone_number", e.target.value)
                }
              />
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <Toaster />
    </Navbar>
  );
}
