import axios from "axios";

const backendApi = "https://api.byteflyt.com";

export default function AxiosInstance(passedHeaders: any = null) {
  let headers: any = passedHeaders ? passedHeaders : {};

  if (localStorage.byte_flyt_remember_user) {
    if (localStorage.byte_flyt_remember_user === "true") {
      if (localStorage.byte_flyt_token) {
        headers.Authorization = `Bearer ${localStorage.byte_flyt_token}`;
      }
    } else {
      if (sessionStorage.byte_flyt_token) {
        headers.Authorization = `Bearer ${sessionStorage.byte_flyt_token}`;
      }
    }
  }

  const axiosInstance = axios.create({
    baseURL: backendApi,
    headers,
  });

  function clearTokenAndRedirectToHome() {
    let byte_flty_admin = false;
    if (localStorage.byte_flyt_admin) {
      byte_flty_admin = localStorage.byte_flyt_admin === "true";
    }
    sessionStorage.clear();
    localStorage.clear();

    if (byte_flty_admin) {
      //@ts-ignore
      window.location = "/byteflyt/login";
    } else {
      //@ts-ignore
      window.location = "/";
    }
  }

  axiosInstance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (
        error.request.responseType === "blob" &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        return new Promise(async (resolve, reject) => {
          const bufferArray = await error.response.data.text();
          const err = JSON.parse(bufferArray);
          reject({ open: true, type: "error", message: err.error });
        });
      }
      if (error.message === "Network Error") {
        return new Promise((resolve, reject) => {
          reject({ open: true, type: "error", message: "Api Not Working" });
        });
      }

      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: "error",
            message: error.response.data.error,
          });
        });
      }

      if (error.response.status === 401) {
        clearTokenAndRedirectToHome();
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: "error",
            message: error.response.data.message,
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: "error",
            message: error.response.data.error || error.response.data.message,
          });
        });
      }

      // reject(error);
    }
  );

  return axiosInstance;
}
