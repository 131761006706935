import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/ByteFlyt Users/login";
import ByteflytLogin from "./Pages/ByteFlyt Admins/byteflyt_login";
import ByteflytDashboard from "./Pages/ByteFlyt Admins/byteflyt_dashboard";
import ByteflytResetPassword from "./Pages/ByteFlyt Admins/byteflyt_resetpassword";
import ResetPassword from "./Pages/ByteFlyt Users/resetpassword";
import Clients from "./Pages/ByteFlyt Admins/clients";
import Dashboard from "./Pages/ByteFlyt Users/dashboard";
import { ClientDetails } from "./Pages/ByteFlyt Admins/client_detail";
import Workforce from "./Pages/ByteFlyt Users/workforce";
import Inventory from "./Pages/ByteFlyt Users/inventory";
import Inventory_History from "./Pages/ByteFlyt Users/inventory_history";
import Reports from "./Pages/ByteFlyt Users/reports";
import Control from "./Pages/ByteFlyt Users/control";
import ConfigureTasks from "./Pages/ByteFlyt Users/configure_tasks";
import Tasks from "./Pages/ByteFlyt Users/tasks";
import TaskDetails from "./Pages/ByteFlyt Users/task_details";
import TaskHistory from "./Pages/ByteFlyt Users/task_history";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/byteflyt/login" element={<ByteflytLogin />} />
        <Route path="/byteflyt/dashboard" element={<ByteflytDashboard />} />
        <Route
          path="/byteflyt/resetpassword"
          element={<ByteflytResetPassword />}
        />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/byteflyt/clients" element={<Clients />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/client_details/:id" element={<ClientDetails />} />
        <Route path="/manage_workforce" element={<Workforce />} />
        <Route path="/inventory/:id" element={<Inventory />} />
        <Route path="/inventory_history/:id" element={<Inventory_History />} />
        <Route path="/reports/:id" element={<Reports />} />
        <Route path="/control/:id" element={<Control />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/configure_tasks/:id" element={<ConfigureTasks />} />
        <Route path="/task_details/:task_id" element={<TaskDetails />} />
        <Route path="/task_history/:task_id" element={<TaskHistory />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
