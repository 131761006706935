import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AxiosInstance from "../../Components/axiosinstance";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import _without from "lodash/without";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function Tasks() {
  const [loader, set_loader] = useState(false);
  const [pending_count, set_pending_count] = useState(0);
  const [completed_count, set_completed_count] = useState(0);
  const [on_hold_count, set_on_hold_count] = useState(0);
  const [assigned_count, set_assigned_count] = useState(0);
  const [sites, set_sites]: any = useState([]);
  const [task_filter, set_task_filter] = useState([
    "Not Started",
    "In Progress",
    "On Hold",
    "Completed",
  ]);
  const [temp_tasks, set_temp_tasks]: any = useState([]);
  const [tasks, set_tasks]: any = useState([]);
  const [site_filter, set_site_filter]: any = useState([]);
  const [site_map, set_site_map]: any = useState({});
  const [anchorElUser, setAnchorElUser]: any = useState(null);
  const [user_info, set_user_info]: any = useState({});
  const [my_id, set_my_id]: any = useState("");
  const [refresh_view, set_refresh_view] = useState(true);
  const [only_my_tasks, set_only_my_tasks] = useState(false);
  const [overdue_count, set_overdue_count] = useState(0);
  const [overdue_task_only, set_overdue_task_only] = useState(false);

  const navigate = useNavigate();

  const task_status = ["Not Started", "In Progress", "On Hold", "Completed"];

  const handle_open_user_info = (
    event: React.MouseEvent<HTMLElement>,
    info: any
  ) => {
    set_user_info(info);
    setAnchorElUser(event.currentTarget);
  };

  const handle_close_user_info = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    set_only_my_tasks(false);
    set_overdue_task_only(false);
    set_loader(true);
    AxiosInstance()
      .get("/users/mytasks")
      .then(({ data }: any) => {
        set_pending_count(data.pending);
        set_completed_count(data.completed);
        set_on_hold_count(data.on_hold);
        set_assigned_count(data.assigned);
        set_sites(data.sites);
        set_overdue_count(data.overdue);
        let temp_site_filter: any = [];
        let temp_site_map: any = {};
        for (let index = 0; index < data.sites.length; index++) {
          temp_site_filter.push(data.sites[index]["_id"]);
          temp_site_map[data.sites[index]["_id"]] = data.sites[index]["name"];
        }
        set_site_map(temp_site_map);
        set_site_filter(temp_site_filter);
        set_task_filter(task_status);
        set_loader(false);
        set_my_id(data.my_id);
        set_temp_tasks(data.tasks);
        set_tasks(data.tasks);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, []);

  const handletaskfilterChange = (event: any) => {
    set_task_filter(event.target.value as string[]);
    handlefilterChange(
      event.target.value,
      site_filter,
      only_my_tasks,
      overdue_task_only
    );
  };
  const my_task_only_change = () => {
    let temp_my_task_only = !only_my_tasks;
    set_only_my_tasks(temp_my_task_only);
    handlefilterChange(
      task_filter,
      site_filter,
      temp_my_task_only,
      overdue_task_only
    );
  };

  const handletaskfilterdelete = (e: React.MouseEvent, value: string) => {
    let local_filter = task_filter;
    local_filter.splice(local_filter.indexOf(value), 1);
    set_task_filter((current) => _without(current, value));
    handlefilterChange(
      local_filter,
      site_filter,
      only_my_tasks,
      overdue_task_only
    );
  };

  const handlesitesfilterChange = (event: any) => {
    set_site_filter(event.target.value as string[]);
    handlefilterChange(
      task_filter,
      event.target.value,
      only_my_tasks,
      overdue_task_only
    );
  };

  const handlesitefilterdelete = (e: React.MouseEvent, value: any) => {
    let local_filter = site_filter;
    local_filter.splice(local_filter.indexOf(value), 1);
    set_site_filter((current: any) => _without(current, value));
    handlefilterChange(
      task_filter,
      local_filter,
      only_my_tasks,
      overdue_task_only
    );
  };

  const handlefilterChange = (
    task_ref: any,
    site_ref: any,
    my_task_ref: any,
    overdue_task_ref: any
  ) => {
    let local_temp_tasks: any = temp_tasks;
    let new_tasks: any = [];
    for (let task of local_temp_tasks) {
      if (overdue_task_ref) {
        if (
          task.overdue &&
          task.overdue === true &&
          task.status !== "Completed"
        ) {
          if (my_task_ref) {
            if (
              site_ref.indexOf(task.site_info._id) !== -1 &&
              task_ref.indexOf(task.status) !== -1 &&
              my_id === task.current_user._id
            ) {
              new_tasks.push(task);
            }
          } else {
            if (
              site_ref.indexOf(task.site_info._id) !== -1 &&
              task_ref.indexOf(task.status) !== -1
            ) {
              new_tasks.push(task);
            }
          }
        }
      } else {
        if (my_task_ref) {
          if (
            site_ref.indexOf(task.site_info._id) !== -1 &&
            task_ref.indexOf(task.status) !== -1 &&
            my_id === task.current_user._id
          ) {
            new_tasks.push(task);
          }
        } else {
          if (
            site_ref.indexOf(task.site_info._id) !== -1 &&
            task_ref.indexOf(task.status) !== -1
          ) {
            new_tasks.push(task);
          }
        }
      }
    }
    set_tasks(new_tasks);
    set_refresh_view(refresh_view);
  };

  const show_overdue_task_only = () => {
    let temp_overdue_task_only = !overdue_task_only;
    set_overdue_task_only(temp_overdue_task_only);
    handlefilterChange(
      task_filter,
      site_filter,
      only_my_tasks,
      temp_overdue_task_only
    );
  };

  return (
    <Navbar>
      <PageLayout heading="Tasks">
        <div style={{ margin: 10, padding: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Paper
                elevation={5}
                style={{ borderRadius: 10, background: "#FFCCCB", margin: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <div>
                    <strong>Pending Tasks:</strong>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 5,
                    fontWeight: 900,
                    fontSize: 24,
                  }}
                >
                  {pending_count}
                </div>
              </Paper>
              <Paper
                elevation={5}
                style={{ borderRadius: 10, background: "#90EE90", margin: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <strong>Completed Tasks:</strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 5,
                    fontWeight: 900,
                    fontSize: 24,
                  }}
                >
                  {completed_count}
                </div>
              </Paper>
              <Paper
                elevation={5}
                style={{
                  borderRadius: 10,
                  background: "#A52A2A",
                  margin: 10,
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <strong>Overdue Tasks:</strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 5,
                    fontWeight: 900,
                    fontSize: 24,
                  }}
                >
                  {overdue_count}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Paper
                elevation={5}
                style={{ borderRadius: 10, background: "#F9E076", margin: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <strong>On Hold Tasks:</strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 5,
                    fontWeight: 900,
                    fontSize: 24,
                  }}
                >
                  {on_hold_count}
                </div>
              </Paper>
              <Paper
                elevation={5}
                style={{ borderRadius: 10, background: "#ADD8E6", margin: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <strong>Assigned Tasks:</strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 5,
                    fontWeight: 900,
                    fontSize: 24,
                  }}
                >
                  {assigned_count}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Paper elevation={5} style={{ borderRadius: 10 }}>
                <div style={{ padding: 5, marginLeft: 10 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FilterAltIcon
                      style={{ color: "black", fontSize: 16, marginRight: 5 }}
                    />{" "}
                    <strong>Filters</strong>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                >
                  <div>
                    <span style={{ float: "right", fontSize: 14 }}>
                      <Checkbox
                        checked={only_my_tasks}
                        size="small"
                        onChange={() => {
                          my_task_only_change();
                        }}
                      />
                      Show Tasks that I need to Work On
                    </span>
                  </div>
                  <div>
                    <span style={{ float: "right", fontSize: 14 }}>
                      <Checkbox
                        checked={overdue_task_only}
                        size="small"
                        onChange={() => {
                          show_overdue_task_only();
                        }}
                      />
                      Show Overdue Tasks only
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex", padding: 5 }}>
                  <FormControl style={{ minWidth: 150 }}>
                    <InputLabel id="demo-mutiple-chip-checkbox-label">
                      Task Filter
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-checkbox-label"
                      id="demo-mutiple-chip-checkbox"
                      multiple
                      value={task_filter}
                      onChange={handletaskfilterChange}
                      label="Task Filter"
                      //input={<Input />}
                      // MenuProps={MenuProps}
                      IconComponent={KeyboardArrowDownIcon}
                      renderValue={(selected) => (
                        <div>
                          {(selected as string[]).map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              clickable
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                              onDelete={(e) => handletaskfilterdelete(e, value)}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {task_status.map((status) => (
                        <MenuItem key={status} value={status}>
                          <Checkbox checked={task_filter.includes(status)} />
                          <ListItemText primary={status} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: "flex", padding: 5 }}>
                  <FormControl style={{ minWidth: 150 }}>
                    <InputLabel id="demo-mutiple-chip-checkbox-label">
                      Site Filter
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-checkbox-label"
                      id="demo-mutiple-chip-checkbox"
                      multiple
                      value={site_filter}
                      onChange={handlesitesfilterChange}
                      label="Site Filter"
                      //input={<Input />}
                      // MenuProps={MenuProps}
                      IconComponent={KeyboardArrowDownIcon}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={site_map[value]}
                              clickable
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                              onDelete={(e) => handlesitefilterdelete(e, value)}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {sites.map((sites: any) => (
                        <MenuItem key={sites} value={sites._id}>
                          <Checkbox
                            checked={site_filter.indexOf(sites._id) !== -1}
                          />
                          <ListItemText primary={sites.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Paper>
            </Grid>
            {tasks.map((task: any) => {
              let chip_style: any = { color: "black" };
              if (
                task.status === "Not Started" ||
                task.status === "In Progress"
              ) {
                chip_style["background"] = "#FFCCCB";
              } else if (task.status === "Completed") {
                chip_style["background"] = "#90EE90";
              } else if (task.status === "On Hold") {
                chip_style["background"] = "#F9E076";
              }

              let show_reassigned = true;
              if (task.assigned_user._id === task.current_user._id) {
                show_reassigned = false;
              }

              return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Paper elevation={5} style={{ borderRadius: 10, margin: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        {task.overdue && task.status !== "Completed" ? (
                          <div
                            style={{
                              marginLeft: 15,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <ReportProblemIcon
                              style={{ color: "#A52A2A", margin: 5 }}
                            />
                            <span
                              style={{
                                fontSize: 28,
                                fontWeight: 900,
                                color: "#A52A2A",
                              }}
                            >
                              {task.task_info.task_title}
                            </span>
                          </div>
                        ) : (
                          <div style={{ marginLeft: 15 }}>
                            <span
                              style={{
                                fontSize: 28,
                                fontWeight: 900,
                              }}
                            >
                              {task.task_info.task_title}
                            </span>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Chip label={task.status} style={chip_style} />
                          {my_id !== task.current_user._id ? (
                            <Tooltip title="You can only view this task or add comments as this task no longer belongs to you">
                              <VisibilityIcon style={{ marginLeft: 5 }} />
                            </Tooltip>
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <div
                          style={{
                            fontSize: 16,
                            marginLeft: 15,
                            marginBottom: 15,
                          }}
                        >
                          <div>
                            <span style={{ fontWeight: 900 }}>Site :</span>{" "}
                            {task.site_info.name}
                          </div>
                          <div>
                            <span style={{ fontWeight: 900 }}>
                              Assigned At :
                            </span>{" "}
                            {moment(task.created_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </div>
                          <div>
                            <span style={{ fontWeight: 900 }}>Deadline :</span>{" "}
                            {task.task_info.deadline ? (
                              <>
                                {moment(task.created_at)
                                  .add(task.task_info.deadline, "minutes")
                                  .format("MMMM Do YYYY, h:mm:ss a")}
                              </>
                            ) : (
                              <>None</>
                            )}
                          </div>
                          <div>
                            <span style={{ fontWeight: 900 }}>
                              Task Owner :
                            </span>{" "}
                            <Tooltip title="Click for User Information">
                              <span
                                style={{
                                  color: "#046499",
                                  fontWeight: 800,
                                }}
                                onClick={(event) => {
                                  handle_open_user_info(
                                    event,
                                    task.assigned_user
                                  );
                                }}
                              >
                                {task.assigned_user.name}{" "}
                                {my_id === task.assigned_user._id ? (
                                  <>
                                    {"("}You{")"}
                                  </>
                                ) : null}
                              </span>
                            </Tooltip>
                          </div>
                          <div>
                            {show_reassigned ? (
                              <>
                                <span style={{ fontWeight: 900 }}>
                                  Currently assigned to :
                                </span>{" "}
                                <Tooltip title="Click for User Information">
                                  <span
                                    style={{
                                      color: "#046499",
                                      fontWeight: 800,
                                    }}
                                    onClick={(event) => {
                                      handle_open_user_info(
                                        event,
                                        task.current_user
                                      );
                                    }}
                                  >
                                    {task.current_user.name}{" "}
                                    {my_id === task.current_user._id ? (
                                      <>
                                        {"("}You{")"}
                                      </>
                                    ) : null}
                                  </span>
                                </Tooltip>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Tooltip title="View Task">
                          <Box
                            onClick={() => {
                              navigate(`/task_details/${task.task_info._id}`);
                            }}
                            style={{
                              background: "#046499",
                              display: "block",
                              cursor: "pointer",
                              justifyContent: "center",
                              alignContent: "center",
                              height: 135,
                              borderBottomRightRadius: 10,
                            }}
                          >
                            <ContentPasteGoIcon
                              style={{
                                color: "white",
                                fontSize: 30,
                                marginLeft: "50%",
                                marginTop: "50%",
                                transform: "translate(-50%,-50%)",
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </PageLayout>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handle_close_user_info}
      >
        <div style={{ margin: 10 }}>
          <div>
            <strong>Name: </strong>
            {user_info.name}
          </div>
          <div>
            <strong>Email: </strong>
            {user_info.email}
          </div>
          <div>
            <strong>Number: </strong>
            {user_info.phone_number}
          </div>
        </div>
      </Menu>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
