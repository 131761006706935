import { Menu, Paper, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import moment from "moment";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function TaskHistory() {
  const [loader, set_loader] = useState(false);
  const [history, set_history]: any = useState({});
  const [accordian_open, set_accordian_open]: any = useState("");
  const [user_info, set_user_info]: any = useState({});
  const [anchorElUser, setAnchorElUser]: any = useState(null);
  const { task_id } = useParams();

  useEffect(() => {
    set_loader(true);
    AxiosInstance()
      .get(`/sites/task_history/${task_id}`)
      .then(({ data }: any) => {
        console.log(data);
        set_history(data.task_history);
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [task_id]);

  const handle_open_user_info = (
    event: React.MouseEvent<HTMLElement>,
    info: any
  ) => {
    set_user_info(info);
    setAnchorElUser(event.currentTarget);
  };

  const hanleAccordianChange = (accordian_name: any) => {
    if (accordian_open === accordian_name) {
      set_accordian_open("");
    } else {
      set_accordian_open(accordian_name);
    }
  };

  const handle_close_user_info = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(null);
  };

  return (
    <Navbar>
      <PageLayout heading="Task History">
        <p style={{ fontSize: 16, fontWeight: 600, margin: 20 }}>
          <span>History for past 60 days</span>
        </p>
        <div style={{ margin: 10, padding: 10 }}>
          {Object.keys(history).map((history_date) => {
            return (
              <Accordion
                expanded={accordian_open === history_date}
                onChange={() => {
                  hanleAccordianChange(history_date);
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    <span style={{ fontSize: 20, fontWeight: 700 }}>
                      Task Date:{" "}
                      {moment(history_date).format("DD/MM/YYYY hh:mm A")}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Paper elevation={5} style={{ padding: 5 }}>
                      <Timeline position="alternate">
                        {history[history_date].map((item: any) => {
                          return (
                            <TimelineItem>
                              <TimelineOppositeContent color="text.secondary">
                                {moment(item.timestamp).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                {item.type === "Info" ? (
                                  <TimelineDot color="secondary" />
                                ) : (
                                  <TimelineDot color="primary" />
                                )}
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Paper elevation={5} style={{ padding: 10 }}>
                                  {item.type === "Info" ? (
                                    <>{item.message}</>
                                  ) : (
                                    <>
                                      <Tooltip title="Click for User Information">
                                        <span
                                          style={{
                                            color: "#046499",
                                            fontWeight: 800,
                                          }}
                                          onClick={(event) => {
                                            handle_open_user_info(
                                              event,
                                              item.user_info[0]
                                            );
                                          }}
                                        >
                                          {item.user_info[0].name}
                                        </span>
                                      </Tooltip>
                                      : {item.message}
                                      {item.other_user_info.length > 0 ? (
                                        <>
                                          {" "}
                                          <Tooltip title="Click for User Information">
                                            <span
                                              style={{
                                                color: "#046499",
                                                fontWeight: 800,
                                              }}
                                              onClick={(event) => {
                                                handle_open_user_info(
                                                  event,
                                                  item.other_user_info[0]
                                                );
                                              }}
                                            >
                                              {item.other_user_info[0].name}
                                            </span>
                                          </Tooltip>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                  {item.reason ? (
                                    <Paper
                                      elevation={5}
                                      style={{ margin: 5, padding: 5 }}
                                    >
                                      <div
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Reason:
                                      </div>
                                      <pre style={{ fontSize: 10 }}>
                                        {item.reason}
                                      </pre>
                                    </Paper>
                                  ) : null}
                                  {item.comment ? (
                                    <Paper
                                      elevation={5}
                                      style={{ margin: 5, padding: 5 }}
                                    >
                                      <div
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Comment:
                                      </div>
                                      <pre style={{ fontSize: 10 }}>
                                        {item.comment}
                                      </pre>
                                    </Paper>
                                  ) : null}
                                </Paper>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        })}
                      </Timeline>
                    </Paper>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </PageLayout>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handle_close_user_info}
      >
        <div style={{ margin: 10 }}>
          <div>
            <strong>Name: </strong>
            {user_info.name}
          </div>
          <div>
            <strong>Email: </strong>
            {user_info.email}
          </div>
          <div>
            <strong>Number: </strong>
            {user_info.phone_number}
          </div>
        </div>
      </Menu>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Navbar>
  );
}
