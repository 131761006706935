import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Dna } from "react-loader-spinner";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Components/axiosinstance";
import Navbar from "../../Components/Navbar";
import PageLayout from "../../Components/PageLayout";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import StartIcon from "@mui/icons-material/Start";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export default function TaskDetails() {
  const [loader, set_loader] = useState(false);
  const { task_id } = useParams();
  const [task_info, set_task_info]: any = useState({});
  const [task_timeline, set_task_timeline] = useState([]);
  const [task_current_status, set_task_current_status]: any = useState(null);
  const [my_id, set_my_id] = useState("");
  const [assigned_user, set_assigned_user]: any = useState({});
  const [current_user, set_current_user]: any = useState({});
  const [refresh, set_refresh] = useState(false);
  const [user_info, set_user_info]: any = useState({});
  const [anchorElUser, setAnchorElUser]: any = useState(null);
  const [open_popup, set_open_popup]: any = useState(false);
  const [add_comment, set_add_comment]: any = useState(false);
  const [popup_input, set_popup_input]: any = useState("");
  const [update_status_info, set_update_status_info]: any = useState(null);
  const [assign_other_popup, set_assign_other_popup]: any = useState(false);
  const [another_user, set_another_user]: any = useState("");
  const [active_users, set_active_users]: any = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    set_loader(true);
    set_user_info({});
    set_open_popup(false);
    set_add_comment(false);
    set_popup_input("");
    set_update_status_info(null);
    set_assign_other_popup(false);
    setAnchorElUser(null);
    AxiosInstance()
      .get(`/sites/task_details/${task_id}`)
      .then(({ data }: any) => {
        console.log(data);
        set_task_info(data.task);
        set_my_id(data.my_id);
        if (data.task_current_status) {
          set_assigned_user(data.task_current_status.assigned_user);
          set_current_user(data.task_current_status.current_user);
          set_task_current_status({
            status: data.task_current_status.status,
            _id: data.task_current_status._id,
          });
          set_task_timeline(data.task_time_line);
          set_another_user(data.my_id);
        }
        set_loader(false);
      })
      .catch((err: any) => {
        toast.error(err.message);
        set_loader(false);
      });
  }, [refresh, task_id]);

  const comment_call = () => {
    if (popup_input.length > 0) {
      set_loader(true);
      AxiosInstance()
        .post(`/sites/task_comment`, { task_id: task_id, comment: popup_input })
        .then(({ data }: any) => {
          toast.success("Comment Added");
          set_refresh(!refresh);
          set_loader(false);
        })
        .catch((err: any) => {
          toast.error(err.message);
          set_loader(false);
        });
    } else {
      toast.error("Provide a comment to add");
    }
  };

  const assign_other_user_call = () => {
    if (my_id === another_user) {
      toast.error("You are already assigned to task, chose another user");
    } else if (popup_input.length === 0) {
      toast.error("Provide a reason to assign another user");
    } else {
      set_loader(true);
      AxiosInstance()
        .post(`/sites/assign_other_user`, {
          task_id: task_id,
          reason: popup_input,
          user_id: another_user,
        })
        .then(({ data }: any) => {
          toast.success("Task Reassigned");
          set_refresh(!refresh);
          set_loader(false);
        })
        .catch((err: any) => {
          toast.error(err.message);
          set_loader(false);
        });
    }
  };

  const update_user_popup = () => {
    set_loader(true);
    AxiosInstance()
      .get("/sites/active_users")
      .then(({ data }: any) => {
        set_active_users(data.active_users);
        set_loader(false);
        set_assign_other_popup(true);
      })
      .catch((err: any) => {
        toast.error("Failed to fetch active users");
        toast.error(err.message);
        set_loader(false);
      });
  };

  const handle_open_user_info = (
    event: React.MouseEvent<HTMLElement>,
    info: any
  ) => {
    set_user_info(info);
    setAnchorElUser(event.currentTarget);
  };

  const handle_close_user_info = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(null);
  };

  const render_chip = (status: any) => {
    let chip_style: any = { color: "black", margin: 2 };
    if (status === "Not Started" || status === "In Progress") {
      chip_style["background"] = "#FFCCCB";
    } else if (status === "Completed") {
      chip_style["background"] = "#90EE90";
    } else if (status === "On Hold") {
      chip_style["background"] = "#F9E076";
    }

    return <Chip label={status} style={chip_style} />;
  };

  const change_task_status = (id: string, status: string) => {
    if (status === "On Hold" || status === "Reopen Task") {
      set_update_status_info({ task_id: id, status: status });
      set_add_comment(false);
      set_popup_input("");
      set_open_popup(true);
    } else {
      AxiosInstance()
        .post(`/sites/change_task_status`, { task_id: id, status: status })
        .then(({ data }: any) => {
          toast.success("Task status updated successfully");
          set_refresh(!refresh);
          set_loader(false);
        })
        .catch((err: any) => {
          toast.error(err.message);
          set_loader(false);
        });
    }
  };

  const update_call = () => {
    if (popup_input.length > 0) {
      AxiosInstance()
        .post(`/sites/change_task_status`, {
          reason: popup_input,
          ...update_status_info,
        })
        .then(({ data }: any) => {
          toast.success("Task status updated successfully");
          set_refresh(!refresh);
          set_loader(false);
        })
        .catch((err: any) => {
          toast.error(err.message);
          set_loader(false);
        });
    } else {
      toast.error("Reason Required to reopen a toask or to put in hold.");
    }
  };
  return (
    <Navbar>
      <PageLayout heading="Task Details">
        <div style={{ margin: 10, padding: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div>
                {task_current_status ? (
                  <>
                    <Button
                      disabled={loader}
                      style={{
                        background: "#046499",
                        color: "white",
                        margin: 2,
                      }}
                      onClick={() => {
                        navigate(`/task_history/${task_id}`);
                      }}
                    >
                      View History
                    </Button>
                    {my_id === current_user._id ? (
                      <Button
                        style={{
                          background: "#046499",
                          color: "white",
                          margin: 2,
                        }}
                        disabled={loader}
                        onClick={update_user_popup}
                      >
                        Assign to Others
                      </Button>
                    ) : null}

                    <Button
                      disabled={loader}
                      style={{
                        background: "#046499",
                        color: "white",
                        margin: 2,
                      }}
                      onClick={() => {
                        set_add_comment(true);
                        set_open_popup(true);
                      }}
                    >
                      Add Comments
                    </Button>
                  </>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <span style={{ float: "right" }}>
                <div>
                  {task_current_status && my_id === current_user._id ? (
                    <>
                      {task_current_status.status === "Completed" ? (
                        <Button
                          onClick={() => {
                            change_task_status(
                              task_current_status._id,
                              "Reopen Task"
                            );
                          }}
                          style={{
                            margin: 2,
                            background: "#FFCCCB",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <RestartAltIcon fontSize="small" />
                            Re-open Task
                          </div>
                        </Button>
                      ) : null}
                      {task_current_status.status === "Not Started" ? (
                        <>
                          <Button
                            onClick={() => {
                              change_task_status(
                                task_current_status._id,
                                "In Progress"
                              );
                            }}
                            style={{
                              margin: 2,
                              background: "#90EE90",
                              color: "black",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <StartIcon fontSize="small" />
                              Start Task
                            </div>
                          </Button>
                          <Button
                            onClick={() => {
                              change_task_status(
                                task_current_status._id,
                                "On Hold"
                              );
                            }}
                            style={{
                              margin: 2,
                              background: "#F9E076",
                              color: "black",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <WatchLaterIcon fontSize="small" />
                              Put On Hold
                            </div>
                          </Button>
                        </>
                      ) : null}
                      {task_current_status.status === "On Hold" ? (
                        <Button
                          onClick={() => {
                            change_task_status(
                              task_current_status._id,
                              "In Progress"
                            );
                          }}
                          style={{
                            margin: 2,
                            justifyContent: "center",
                            background: "#90EE90",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              color: "black",
                            }}
                          >
                            <BeenhereIcon fontSize="small" />
                            Resume Task
                          </div>
                        </Button>
                      ) : null}
                      {task_current_status.status === "In Progress" ? (
                        <>
                          <Button
                            onClick={() => {
                              change_task_status(
                                task_current_status._id,
                                "Completed"
                              );
                            }}
                            style={{
                              margin: 2,
                              justifyContent: "center",
                              background: "#90EE90",
                              color: "black",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <BeenhereIcon fontSize="small" />
                              Complete Task
                            </div>
                          </Button>
                          <Button
                            onClick={() => {
                              change_task_status(
                                task_current_status._id,
                                "On Hold"
                              );
                            }}
                            style={{
                              margin: 2,
                              justifyContent: "center",
                              background: "#F9E076",
                              color: "black",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <WatchLaterIcon fontSize="small" />
                              Put on Hold
                            </div>
                          </Button>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {task_current_status ? (
                    <>{render_chip(task_current_status.status)}</>
                  ) : (
                    <>
                      <Chip
                        label="Not Scheduled Yet"
                        style={{
                          background: "grey",
                          color: "black",
                          margin: 2,
                        }}
                      />
                    </>
                  )}
                </div>
              </span>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
              <Paper elevation={5} style={{ marginTop: 10, borderRadius: 10 }}>
                <div
                  style={{
                    fontSize: 28,
                    color: "white",
                    fontWeight: 800,
                    background: "#046499",
                    borderRadius: 10,
                  }}
                >
                  <span style={{ margin: 10 }}>{task_info.task_title}</span>
                </div>
                <div
                  style={{
                    fontSize: 20,
                    color: "#046499",
                    margin: 10,
                    fontWeight: 600,
                  }}
                >
                  Task Description
                </div>
                <div style={{ margin: 10, fontSize: 14 }}>
                  <pre>{task_info.task_description}</pre>
                </div>
                <p></p>
              </Paper>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 800,
                  borderRadius: 10,
                }}
              >
                <span style={{ margin: 20 }}>
                  Task Owner:{" "}
                  <Tooltip title="Click for User Information">
                    <span
                      style={{
                        color: "#046499",
                        fontWeight: 800,
                      }}
                      onClick={(event) => {
                        handle_open_user_info(event, assigned_user);
                      }}
                    >
                      {assigned_user.name}{" "}
                      {my_id === assigned_user._id ? (
                        <>
                          {"("}You{")"}
                        </>
                      ) : null}
                    </span>
                  </Tooltip>
                </span>
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 800,
                  borderRadius: 10,
                }}
              >
                <span style={{ margin: 20 }}>
                  Currently Assigned to:{" "}
                  <Tooltip title="Click for User Information">
                    <span
                      style={{
                        color: "#046499",
                        fontWeight: 800,
                      }}
                      onClick={(event) => {
                        handle_open_user_info(event, current_user);
                      }}
                    >
                      {current_user.name}{" "}
                      {my_id === current_user._id ? (
                        <>
                          {"("}You{")"}
                        </>
                      ) : null}
                    </span>
                  </Tooltip>
                </span>
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
              <Paper elevation={5}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: 24,
                    color: "#046499",
                    margin: 10,
                    fontWeight: 800,
                  }}
                >
                  Timeline
                </div>
                <div style={{ margin: 2, padding: 2 }}>
                  <Timeline position="alternate">
                    {task_timeline.map((item: any) => {
                      return (
                        <TimelineItem>
                          <TimelineOppositeContent color="text.secondary">
                            {moment(item.timestamp).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            {item.type === "Info" ? (
                              <TimelineDot color="secondary" />
                            ) : (
                              <TimelineDot color="primary" />
                            )}
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={5} style={{ padding: 10 }}>
                              {item.type === "Info" ? (
                                <>{item.message}</>
                              ) : (
                                <>
                                  <Tooltip title="Click for User Information">
                                    <span
                                      style={{
                                        color: "#046499",
                                        fontWeight: 800,
                                      }}
                                      onClick={(event) => {
                                        handle_open_user_info(
                                          event,
                                          item.user_info[0]
                                        );
                                      }}
                                    >
                                      {item.user_info[0].name}
                                    </span>
                                  </Tooltip>
                                  : {item.message}
                                  {item.other_user_info.length > 0 ? (
                                    <>
                                      {" "}
                                      <Tooltip title="Click for User Information">
                                        <span
                                          style={{
                                            color: "#046499",
                                            fontWeight: 800,
                                          }}
                                          onClick={(event) => {
                                            handle_open_user_info(
                                              event,
                                              item.other_user_info[0]
                                            );
                                          }}
                                        >
                                          {item.other_user_info[0].name}
                                        </span>
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </>
                              )}
                              {item.reason ? (
                                <Paper
                                  elevation={5}
                                  style={{ margin: 5, padding: 5 }}
                                >
                                  <div
                                    style={{ fontSize: 16, fontWeight: 600 }}
                                  >
                                    Reason:
                                  </div>
                                  <pre style={{ fontSize: 10 }}>
                                    {item.reason}
                                  </pre>
                                </Paper>
                              ) : null}
                              {item.comment ? (
                                <Paper
                                  elevation={5}
                                  style={{ margin: 5, padding: 5 }}
                                >
                                  <div
                                    style={{ fontSize: 16, fontWeight: 600 }}
                                  >
                                    Comment:
                                  </div>
                                  <pre style={{ fontSize: 10 }}>
                                    {item.comment}
                                  </pre>
                                </Paper>
                              ) : null}
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </PageLayout>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handle_close_user_info}
      >
        <div style={{ margin: 10 }}>
          <div>
            <strong>Name: </strong>
            {user_info.name}
          </div>
          <div>
            <strong>Email: </strong>
            {user_info.email}
          </div>
          <div>
            <strong>Number: </strong>
            {user_info.phone_number}
          </div>
        </div>
      </Menu>

      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <Modal
        open={open_popup}
        onClose={() => {
          set_open_popup(false);
        }}
        style={{ marginTop: "10%", width: "30%", marginLeft: "35%" }}
      >
        <Paper elevation={5} style={{ overflowX: "scroll" }}>
          <div style={{ padding: 10 }}>
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Comments"
              multiline
              variant="outlined"
              type="text"
              value={popup_input}
              onChange={(e) => {
                set_popup_input(e.target.value);
              }}
            />
            {add_comment ? (
              <Button
                variant="contained"
                style={{
                  background: "#046499",
                  fontSize: 16,
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                  width: "90%",
                }}
                disabled={loader}
                onClick={comment_call}
              >
                Comment
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  background: "#046499",
                  fontSize: 16,
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                  width: "90%",
                }}
                disabled={loader}
                onClick={update_call}
              >
                Update Status
              </Button>
            )}
          </div>
        </Paper>
      </Modal>
      <Modal
        open={assign_other_popup}
        onClose={() => {
          set_assign_other_popup(false);
        }}
        style={{ marginTop: "10%", width: "30%", marginLeft: "35%" }}
      >
        <Paper elevation={5} style={{ overflowX: "scroll" }}>
          <FormControl style={{ width: "90%", marginTop: 10 }}>
            <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={another_user}
              onChange={(e) => {
                set_another_user(e.target.value);
              }}
              label="Assigned To"
            >
              {active_users.map((user: any) => {
                return <MenuItem value={user._id}>{user.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <div style={{ padding: 10 }}>
            <TextField
              style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
              id="outlined-basic"
              label="Reason"
              multiline
              variant="outlined"
              type="text"
              value={popup_input}
              onChange={(e) => {
                set_popup_input(e.target.value);
              }}
            />

            <Button
              variant="contained"
              style={{
                background: "#046499",
                fontSize: 16,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
                width: "90%",
              }}
              disabled={loader}
              onClick={assign_other_user_call}
            >
              Assign
            </Button>
          </div>
        </Paper>
      </Modal>
    </Navbar>
  );
}
