import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import byte_flyt_logo from "../../Constants/Media/Images/byte_flyt_logo.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import wave_line from "../../Constants/Media/SVG/wave_line.svg";
import "../../Constants/Fonts/qanelas-soft-cufonfonts-webfont/style.css";
import toast, { Toaster } from "react-hot-toast";
import AxiosInstance from "../../Components/axiosinstance";
import { Dna } from "react-loader-spinner";
import { Link } from "react-router-dom";

export default function ByteflytLogin() {
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [show_password, set_show_password] = useState(false);
  const [remember_me, set_remember_me] = useState(false);
  const [loader, set_loader] = useState(false);
  const [new_password, set_new_password] = useState(false);
  const [login_data, set_login_data] = useState({});
  const [show_reenter_password, set_show_reenter_password] = useState(false);
  const [reenter_password, set_reenter_password] = useState("");

  const handleClickShowPassword = () => set_show_password(!show_password);
  const handleMouseDownPassword = () => set_show_password(!show_password);

  useEffect(() => {
    set_new_password(false);
    if (localStorage.byte_flyt_token || sessionStorage.byte_flyt_token) {
      set_loader(true);
      AxiosInstance()
        .get("/users/verify")
        .then(({ data }: any) => {
          localStorage.setItem(
            "byte_flyt_user_info",
            JSON.stringify(data.user_info)
          );
          localStorage.setItem(
            "byte_flyt_user_navbar",
            JSON.stringify(data.user_navbar)
          );
          // @ts-ignore
          window.location = data.landing_link;
          set_loader(false);
        })
        .catch((err: any) => {
          set_loader(false);
        });
    }
  }, []);

  const change_password = () => {
    if (password.length > 7) {
      if (password !== reenter_password) {
        toast.error("Both the Passwords do not match.");
      } else {
        set_loader(true);
        AxiosInstance()
          .post("/users/changepassword", {
            password: password,
          })
          .then(({ data }: any) => {
            toast.success(
              "Password reset successfully. Please use the new password to Login."
            );
            set_loader(false);
            // @ts-ignore
            save_and_navigate(login_data);
          })
          .catch((err: any) => {
            toast.error(err.message);
            set_loader(false);
          });
      }
    } else {
      toast.error("Password must contain atleast 8 characters.");
    }
  };

  const save_and_navigate = (data: any) => {
    // @ts-ignore
    window.location = data.landing_link;
  };

  const handleLogin = () => {
    if (email.length === 0 || password.length === 0) {
      toast.error("Please fill all the fields.");
    } else {
      if (email.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.com$")) {
        set_loader(true);
        AxiosInstance()
          .post("/users/login", {
            email: email,
            password: password,
          })
          .then(({ data }: any) => {
            console.log(data);
            if (remember_me) {
              localStorage.setItem("byte_flyt_remember_user", "true");
              localStorage.setItem("byte_flyt_token", data.token);
            } else {
              localStorage.setItem("byte_flyt_remember_user", "false");
              sessionStorage.setItem("byte_flyt_token", data.token);
            }
            localStorage.setItem(
              "byte_flyt_user_info",
              JSON.stringify(data.user_info)
            );
            localStorage.setItem(
              "byte_flyt_user_navbar",
              JSON.stringify(data.user_navbar)
            );

            if (data.password_set) {
              save_and_navigate(data);
            } else {
              set_show_password(false);
              set_new_password(true);
              set_password("");
              set_login_data(data);
            }
            set_loader(false);
          })
          .catch((err: any) => {
            toast.error(err.message);
            set_loader(false);
          });
      } else {
        toast.error("Invalid email format.");
        set_loader(false);
      }
    }
  };

  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        position: "absolute",
        top: 0,
        width: "100%",
        bottom: 0,
        backgroundColor: "black",
        backgroundImage: `url(${wave_line})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        fontFamily: "Qanelas Soft Medium",
        fontSize: 12,
      }}
    >
      <Grid container spacing={2} p={2}>
        <Grid item xs={1} sm={3} lg={4.5} xl={5}></Grid>
        <Grid item xs={10} sm={6} lg={3} xl={2}>
          <Paper elevation={10} style={{ borderRadius: 40 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 5,
                paddingBottom: 5,
              }}
              p={2}
            >
              <img src={byte_flyt_logo} style={{ width: "90%" }} />
              {new_password ? (
                <>
                  <p>Change you password to continue!</p>
                  <TextField
                    style={{ width: "90%", marginBottom: 10 }}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    disabled={loader}
                    type={show_password ? "text" : "password"}
                    value={password}
                    onChange={(e) => set_password(e.target.value)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => set_show_password(!show_password)}
                            onMouseDown={() =>
                              set_show_password(!show_password)
                            }
                          >
                            {show_password ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    style={{ width: "90%", marginBottom: 10 }}
                    id="outlined-basic"
                    label="Re-Enter Password"
                    variant="outlined"
                    disabled={loader}
                    type={show_reenter_password ? "text" : "password"}
                    value={reenter_password}
                    onChange={(e) => set_reenter_password(e.target.value)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              set_show_reenter_password(!show_reenter_password)
                            }
                            onMouseDown={() =>
                              set_show_reenter_password(!show_reenter_password)
                            }
                          >
                            {show_reenter_password ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    style={{
                      background: "#046499",
                      fontSize: 16,
                      marginBottom: 10,
                      width: "70%",
                      borderRadius: 10,
                    }}
                    onClick={change_password}
                    disabled={loader}
                  >
                    Next
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    style={{ width: "90%", marginBottom: 10, fontSize: 10 }}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={email}
                    disabled={loader}
                    type="email"
                    onChange={(e) => set_email(e.target.value)}
                  />
                  <TextField
                    style={{ width: "90%", marginBottom: 10 }}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    disabled={loader}
                    type={show_password ? "text" : "password"}
                    value={password}
                    onChange={(e) => set_password(e.target.value)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {show_password ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div>
                    <Checkbox
                      checked={remember_me}
                      onChange={(e) => set_remember_me(e.target.checked)}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      disabled={loader}
                    />
                    <span>Remember me</span>
                  </div>
                  <Button
                    variant="contained"
                    style={{
                      background: "#046499",
                      fontSize: 16,
                      marginBottom: 10,
                      width: "70%",
                      borderRadius: 10,
                    }}
                    onClick={handleLogin}
                    disabled={loader}
                  >
                    Login
                  </Button>
                  <Link to="/resetpassword">Forgot Password?</Link>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={3} lg={4.5} xl={5}></Grid>
      </Grid>
      <Toaster />
      <Dna
        visible={loader}
        height="20%"
        width="20%"
        ariaLabel="dna-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}
